import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { SubscriptionsBranchV2Api } from '@app/api';
import type { BranchSubscriptionInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateSubscription(
  branchId: string,
  subscriptionId: string,
  branchSubscriptionInput: BranchSubscriptionInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new SubscriptionsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateBranchSubscription(branchId, subscriptionId, branchSubscriptionInput).then((res) => res.data);
}

export default function useUpdateSubscription(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; subscriptionId: string; branchSubscriptionInput: BranchSubscriptionInput }) =>
      updateSubscription(values.branchId, values.subscriptionId, values.branchSubscriptionInput, axiosInstance),
    props
  );
}
