/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomVariableCampaignImageInput } from '../model';
// @ts-ignore
import { CustomVariableCampaignImageResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { ImageAttachment } from '../model';
/**
 * ImageAttachmentsBranchV2Api - axios parameter creator
 * @export
 */
export const ImageAttachmentsBranchV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomVariableCampaignImage: async (branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('createCustomVariableCampaignImage', 'branchId', branchId)
            // verify required parameter 'customVariableCampaignImageInput' is not null or undefined
            assertParamExists('createCustomVariableCampaignImage', 'customVariableCampaignImageInput', customVariableCampaignImageInput)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/bannerbear`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customVariableCampaignImageInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete branch image
         * @summary Delete branch image
         * @param {string} branchId The id of the branch
         * @param {string} imageId The id of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranchImage: async (branchId: string, imageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('deleteBranchImage', 'branchId', branchId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteBranchImage', 'imageId', imageId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/{image_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete profile picture for branch
         * @summary Delete profile picture
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilePicture: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('deleteProfilePicture', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/destroy_profile_picture`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload profile picture for branch
         * @summary Upload profile picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePicture: async (branchId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('uploadProfilePicture', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/profile_picture`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload store picture for branch
         * @summary Upload store picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStorePicture: async (branchId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('uploadStorePicture', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/store_picture`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageAttachmentsBranchV2Api - functional programming interface
 * @export
 */
export const ImageAttachmentsBranchV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageAttachmentsBranchV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomVariableCampaignImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete branch image
         * @summary Delete branch image
         * @param {string} branchId The id of the branch
         * @param {string} imageId The id of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBranchImage(branchId: string, imageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBranchImage(branchId, imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete profile picture for branch
         * @summary Delete profile picture
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfilePicture(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfilePicture(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload profile picture for branch
         * @summary Upload profile picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilePicture(branchId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfilePicture(branchId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload store picture for branch
         * @summary Upload store picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStorePicture(branchId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStorePicture(branchId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageAttachmentsBranchV2Api - factory interface
 * @export
 */
export const ImageAttachmentsBranchV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageAttachmentsBranchV2ApiFp(configuration)
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): AxiosPromise<CustomVariableCampaignImageResponse> {
            return localVarFp.createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete branch image
         * @summary Delete branch image
         * @param {string} branchId The id of the branch
         * @param {string} imageId The id of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranchImage(branchId: string, imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBranchImage(branchId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete profile picture for branch
         * @summary Delete profile picture
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilePicture(branchId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProfilePicture(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload profile picture for branch
         * @summary Upload profile picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePicture(branchId: string, file?: any, options?: any): AxiosPromise<ImageAttachment> {
            return localVarFp.uploadProfilePicture(branchId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload store picture for branch
         * @summary Upload store picture
         * @param {string} branchId The id of the branch
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStorePicture(branchId: string, file?: any, options?: any): AxiosPromise<ImageAttachment> {
            return localVarFp.uploadStorePicture(branchId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageAttachmentsBranchV2Api - interface
 * @export
 * @interface ImageAttachmentsBranchV2Api
 */
export interface ImageAttachmentsBranchV2ApiInterface {
    /**
     * Create bannerbear image with given modifications
     * @summary Create bannerbear image
     * @param {string} branchId The id of the branch
     * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): AxiosPromise<CustomVariableCampaignImageResponse>;

    /**
     * Delete branch image
     * @summary Delete branch image
     * @param {string} branchId The id of the branch
     * @param {string} imageId The id of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    deleteBranchImage(branchId: string, imageId: string, options?: any): AxiosPromise<void>;

    /**
     * Delete profile picture for branch
     * @summary Delete profile picture
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    deleteProfilePicture(branchId: string, options?: any): AxiosPromise<void>;

    /**
     * Upload profile picture for branch
     * @summary Upload profile picture
     * @param {string} branchId The id of the branch
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    uploadProfilePicture(branchId: string, file?: any, options?: any): AxiosPromise<ImageAttachment>;

    /**
     * Upload store picture for branch
     * @summary Upload store picture
     * @param {string} branchId The id of the branch
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    uploadStorePicture(branchId: string, file?: any, options?: any): AxiosPromise<ImageAttachment>;

}

/**
 * ImageAttachmentsBranchV2Api - object-oriented interface
 * @export
 * @class ImageAttachmentsBranchV2Api
 * @extends {BaseAPI}
 */
export class ImageAttachmentsBranchV2Api extends BaseAPI implements ImageAttachmentsBranchV2ApiInterface {
    /**
     * Create bannerbear image with given modifications
     * @summary Create bannerbear image
     * @param {string} branchId The id of the branch
     * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete branch image
     * @summary Delete branch image
     * @param {string} branchId The id of the branch
     * @param {string} imageId The id of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public deleteBranchImage(branchId: string, imageId: string, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).deleteBranchImage(branchId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete profile picture for branch
     * @summary Delete profile picture
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public deleteProfilePicture(branchId: string, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).deleteProfilePicture(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload profile picture for branch
     * @summary Upload profile picture
     * @param {string} branchId The id of the branch
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public uploadProfilePicture(branchId: string, file?: any, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).uploadProfilePicture(branchId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload store picture for branch
     * @summary Upload store picture
     * @param {string} branchId The id of the branch
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public uploadStorePicture(branchId: string, file?: any, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).uploadStorePicture(branchId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
