import React from 'react';

import { date, currency as currencyLib } from '@app/lib';
import Table from 'components/Table';
import useLocale from '@app/hooks/useLocale';

type TransactionTableProps = {
  currency: string;
  data: Array<any>;
};

export type Transaction = {
  id: string;
  amount: number;
  status: string;
  transaction_date: string;
};

export default function SubscriptionTransactionsTable({ data }: TransactionTableProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet',
  };
  const { t } = useLocale();

  const columns = [
    {
      Header: t('subscription.table.date', SCOPE_OPTIONS),
      accessor: (row: Transaction) => <span className={`pl-6 font-bold`}>{date.format(row.transaction_date, 'dd.MM.yyyy')}</span>,
    },
    {
      Header: t('subscription.table.amount', SCOPE_OPTIONS),
      accessor: (row: Transaction) => <span className="pl-6">{currencyLib.handleCents(row.amount)} TRY</span>,
    },
    {
      Header: t('subscription.table.status', SCOPE_OPTIONS),
      accessor: (row: Transaction) => <span className="pl-6">{row.status}</span>,
    },
  ];

  return <Table columns={columns} data={data} header disableSortBy defaultSortBy={[{ id: 'created_at', desc: true }]} />;
}
