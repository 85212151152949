import React from 'react';

import cn from 'classnames';
import { Controller } from 'react-hook-form';
import Phone from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { phoneNumber } from '@app/lib';

type PhoneInputPropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  defaultValue?: number | string;
  control: any;
  rules?: any;
  requiredSign?: boolean;
  disabled?: boolean;
  inputClassname?: string;
  country?: string;
  onlyCountries?: Array<string>;
  containerClassname?: string;
  buttonClassname?: string;
};

const DEFAULT_COUNTRY = 'tr';

const PhoneInput: React.FC<PhoneInputPropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  defaultValue,
  control,
  rules,
  requiredSign = false,
  disabled = false,
  inputClassname,
  country,
  onlyCountries,
  containerClassname,
  buttonClassname,
}) => {
  function selectedCountry(country: string | undefined | null) {
    if (country === null || typeof country === 'undefined' || country === 'en') {
      return 'tr';
    }

    return country;
  }

  return (
    <div className={cn('flex flex-col mb-2.5', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-black-800">
          {label}
          {requiredSign && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => (
          <Phone
            onlyCountries={onlyCountries || undefined}
            onBlur={onBlur}
            country={selectedCountry(country) || DEFAULT_COUNTRY}
            value={value}
            onChange={(phoneNumberText) => {
              const handledPhone = phoneNumber.format(phoneNumberText);

              if (handledPhone === '+') {
                onChange('');
                return;
              }
              onChange(handledPhone);
            }}
            containerClass={cn(
              {
                'react-tel-input--error': error,
              },
              containerClassname
            )}
            inputClass={cn(
              {
                '!bg-white': !disabled,
                '!bg-gray-200': disabled,
              },
              inputClassname
            )}
            buttonClass={cn(
              {
                '!bg-white': !disabled,
                '!bg-gray-200': disabled,
              },
              buttonClassname
            )}
            disabled={disabled}
          />
        )}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default PhoneInput;
