import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ImageAttachmentsBranchV2Api } from 'api/api';

import { useAuth, useAxios } from '@app/hooks';

export function deleteImage(branchId: string, imageId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ImageAttachmentsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteBranchImage(branchId, imageId).then((res) => res.data);
}

export default function useDeleteImage(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((imageId: string) => deleteImage(branchId, imageId, axiosInstance), props);
}
