import { useRouter } from 'next/router';
import React from 'react';

import { useAuth, useLocale } from '@app/hooks';

const CampaignTypeTabs: React.FC<{ activeTab: string }> = ({ activeTab }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CampaignTypeTab',
  };
  const { t } = useLocale();

  const router = useRouter();
  const { branch } = useAuth();
  const hasCanvaFeature = branch?.company?.feature_flags?.has_canva_feature;

  return (
    <div className="flex px-4 bg-gray-200 rounded-lg w-max mb-4">
      <button
        className={`px-4 py-3 font-semibold text-sm relative ${activeTab === 'pending' ? 'text-blue-600' : 'text-gray-500'}`}
        onClick={() => router.push('/campaigns/pending')}
      >
        {t('imageAds', SCOPE_OPTIONS)}
        {activeTab === 'pending' && (
          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-1 bg-blue-600 rounded-tl-full rounded-tr-full"></span>
        )}
      </button>
      <button
        className={`px-4 py-3 font-semibold text-sm relative ${activeTab === 'videos' ? 'text-blue-600' : 'text-gray-500'}`}
        onClick={() => router.push('/campaigns/videos')}
      >
        {t('videoAds', SCOPE_OPTIONS)}
        {activeTab === 'videos' && (
          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-1 bg-blue-600 rounded-tl-full rounded-tr-full"></span>
        )}
      </button>
      {hasCanvaFeature && (
        <button
          className={`px-4 py-3 font-semibold text-sm relative ${activeTab === 'images' ? 'text-blue-600' : 'text-gray-500'}`}
          onClick={() => router.push('/campaigns/images')}
        >
          {t('designs', SCOPE_OPTIONS)}
          {activeTab === 'images' && (
            <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-1 bg-blue-600 rounded-tl-full rounded-tr-full"></span>
          )}
        </button>
      )}
    </div>
  );
};

export default CampaignTypeTabs;
