import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { branch as branchLib, date as dateLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Box, Form, Icons } from '@app/components';

import CustomInput from './CustomInput';

type EndDatePropTypes = {
  stopTime?: string;
  startTime: string;
  campaignStopTime?: string;
  isEdit?: boolean;
  minDateRange?: number;
  isGooglePlatform?: boolean;
};

const EndDate: React.FC<EndDatePropTypes> = ({ stopTime, startTime, minDateRange, isGooglePlatform }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.DateForm.EndDate',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const stopTimeWatch = useWatch({
    control,
    name: 'stopTime',
  });
  const startTimeWatch = useWatch({
    control,
    name: 'startTime',
    defaultValue: startTime,
  });

  const noEndDateWatch = watch('noEndDate');

  const minDate = dateLib.JSDateToISO(dateLib.plus(dateLib.ISOtoJSDate(startTimeWatch), { hours: 24 }));
  const minMaxTime = dateLib.isValid(stopTime) ? getMinMaxTime() : { maxTime: undefined, minTime: undefined };
  const MINIMUM_DATE = branchLib.minCampaignDateRange(branch);

  function getMinMaxTime() {
    const minDiffDate = minDate && dateLib.differenceDate(dateLib.ISOtoJSDate(minDate), dateLib.ISOtoJSDate(stopTimeWatch));
    const stopDiffDate = stopTime && dateLib.differenceDate(dateLib.ISOtoJSDate(stopTime), dateLib.ISOtoJSDate(stopTimeWatch));

    if (stopDiffDate === 1 && stopTime) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(stopTime, {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(dateLib.format(stopTime, 'HH:mm')),
      };
    }

    if (minDiffDate > 0 && stopDiffDate !== 1) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(dateLib.now(), {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(
          dateLib.set(dateLib.now(), {
            hour: 23,
            minute: 59,
          })
        ),
      };
    }

    return {
      minTime: dateLib.ISOtoJSDate(dateLib.format(minDate, 'HH:mm')),
      maxTime: dateLib.ISOtoJSDate(
        dateLib.set(minDate, {
          hour: 23,
          minute: 59,
        })
      ),
    };
  }
  return (
    <Box className="bg-white p-5 !mb-0">
      <div className="flex items-center justify-between mb-4">
        <label className="block text-3.5 font-semibold text-gray-900 whitespace-nowrap mr-2">
          {t('labels.stopDate', SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
        {errors.stopTime && (
          <div className="flex items-center flex-end">
            <span className="inline text-red-400 text-3">{errors.stopTime.message}</span>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <Form.Date
          id="stopTime"
          name="stopTime"
          dateFormat={isGooglePlatform ? 'd MMMM yyyy' : 'd MMMM yyyy HH:mm'}
          timeIntervals={1}
          showTimeSelect={!isGooglePlatform ?? true}
          control={control}
          error={errors.stopTime}
          rules={{
            validate: (date: string) => {
              const diff = dateLib.diff(startTimeWatch, date, ['hours']);
              const diffDays = dateLib.diff(startTimeWatch, date, ['days']);

              if (minDateRange) {
                if ((diffDays.days as number) < minDateRange) {
                  return t('errors.validate', { ...SCOPE_OPTIONS, value: `${minDateRange}` });
                }
              }
              if ((diff.hours as number) < MINIMUM_DATE * 24) {
                return t('errors.validate', { ...SCOPE_OPTIONS, value: MINIMUM_DATE });
              }
            },
          }}
          customInput={<CustomInput isEndDate />}
          minDate={dateLib.ISOtoJSDate(minDate)}
          maxDate={dateLib.isValid(stopTime) ? dateLib.ISOtoJSDate(stopTime as string) : undefined}
          minTime={minMaxTime.minTime}
          maxTime={minMaxTime.maxTime}
          defaultValue={stopTimeWatch}
        />
      </div>
      {!noEndDateWatch && (
        <div className="flex items-center gap-1 text-blue-600 mt-2 text-xs">
          <span>
            <Icons.SendFill width={14} />
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: t('campaignDuration', {
                ...SCOPE_OPTIONS,
                count: Number(dateLib.differenceDate(dateLib.ISOtoJSDate(startTimeWatch), dateLib.ISOtoJSDate(stopTimeWatch))) || 1,
                day: dateLib.differenceDate(dateLib.ISOtoJSDate(startTimeWatch), dateLib.ISOtoJSDate(stopTimeWatch)),
              }),
            }}
          ></span>
        </div>
      )}
      {!branch.company?.feature_flags?.has_centric_payment_feature && (
        <Form.Checkbox id="noEndDate" className="mt-2" {...register('noEndDate')} label={t('indefinite', SCOPE_OPTIONS)} />
      )}
    </Box>
  );
};

export default EndDate;
