import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { SubscriptionsBranchV2Api, WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getSubscriptionPlans(branchId: string, axiosInstance: AxiosInstance) {
  const api = new SubscriptionsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getBranchSubscriptions(branchId).then((res) => res.data);
}

export default function useGetSubscriptionPlans() {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(['subscriptionPlans', branchId], () => getSubscriptionPlans(branchId, axiosInstance));
}
