import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Icons } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';

import Collapse from './Collapse';
import Preferences from './Preferences';

const Logo = dynamic(() => import('../Logo'), {
  ssr: false,
});

type SidebarPropTypes = {
  onCollapseClick: (status: boolean) => void;
  className: string;
  miniSidebar: boolean;
};

const Sidebar: React.FC<SidebarPropTypes> = ({ className, miniSidebar, onCollapseClick }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Sidebar',
  };
  const { t, locale } = useLocale();
  const router = useRouter();
  const [activeRoute, setActiveRoute] = useState<
    | {
        key: string;
        Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
        url: string;
        activePaths: string[];
        detail?: {
          name: string;
          url: string;
          activePaths: string[];
        }[];
      }
    | undefined
  >(undefined);
  const { branch } = useAuth();
  const company = branch?.company;
  const walletUrl = company?.feature_flags?.has_centric_payment_feature
    ? '/wallet'
    : company?.feature_flags?.has_subscription_feature
    ? '/wallet/subscriptions'
    : '/wallet';

  const ROUTES = [
    {
      key: 'dashboard',
      Icon: Icons.Dashboard,
      url: '/dashboard',
      activePaths: ['/', '/dashboard'],
    },
    {
      key: 'campaign',
      Icon: Icons.Campaign,
      url: '/campaigns',
      detail: [
        {
          name: t('actions.pending', SCOPE_OPTIONS),
          url: '/campaigns/pending',
          activePaths: [
            '/campaigns/pending',
            '/campaigns/videos',
            '/campaigns/create/image/[id]',
            '/campaigns/create/[id]',
            '/campaigns/create/google/[id]',
          ],
        },
        {
          name: t('actions.campaigns', SCOPE_OPTIONS),
          url: '/campaigns',
          activePaths: ['/campaigns', '/campaigns/[id]', '/campaigns/edit/[id]', '/campaigns/google/[id]', '/campaigns/edit/google/[id]'],
        },
      ],
      activePaths: [
        '/campaigns',
        '/campaigns/create/[id]',
        '/campaigns/create/image/[id]',
        '/campaigns/[id]',
        '/campaigns/google/[id]',
        '/campaigns/edit/google/[id]',
        '/campaigns/edit/[id]',
        '/campaigns/pending',
        '/campaigns/videos',
        '/campaigns/images',
        '/campaigns/create/google/[id]',
      ],
    },
    {
      key: 'mediaLibrary',
      Icon: Icons.MediaLibrary,
      url: '/media-library',
      detail: [
        {
          name: t('actions.mediaLibrary', SCOPE_OPTIONS),
          url: '/media-library',
          activePaths: ['/media-library', '/media-library/[id]'],
        },
      ],
      activePaths: ['/media-library', '/media-library/[id]'],
    },
    {
      key: 'socialMedia',
      Icon: Icons.SocialMedia,
      url: '/social-media-management',
      detail: [
        {
          name: t('actions.createPost', SCOPE_OPTIONS),
          url: '/social-media-management',
          activePaths: ['/social-media-management', '/social-media-management/[id]'],
        },
      ],
      activePaths: ['/social-media-management', '/social-media-management/[id]'],
    },
    {
      key: 'leadGen',
      Icon: Icons.LeadGen,
      url: '/leadgen',
      activePaths: ['/', '/leadgen'],
    },
    // {
    //   key: 'activities',
    //   Icon: Icons.Activities,
    //   url: '/activities',
    //   activePaths: ['/activities'],
    // },
    {
      key: 'wallet',
      Icon: Icons.Wallet,
      url: walletUrl,
      detail: [
        {
          name: t('actions.subscriptions', SCOPE_OPTIONS),
          url: '/wallet/subscriptions',
          activePaths: ['/wallet/subscriptions'],
        },
        {
          name: t('actions.paymentCards', SCOPE_OPTIONS),
          url: '/wallet/cards',
          activePaths: ['/wallet/cards'],
        },
        {
          name: t('actions.invoices', SCOPE_OPTIONS),
          url: '/wallet/invoices',
          activePaths: ['/wallet/invoices'],
        },
        {
          name: t('actions.myWallet', SCOPE_OPTIONS),
          url: '/wallet',
          activePaths: ['/wallet'],
        },
      ],
      activePaths: ['/wallet/subscriptions', '/wallet/cards', '/wallet/invoices', '/wallet'],
    },
    {
      key: 'settings',
      Icon: Icons.Settings,
      url: '/settings',
      detail: [
        { name: t('actions.branchSettings', SCOPE_OPTIONS), url: '/branches/edit', activePaths: ['/branches/edit'] },
        { name: t('actions.integrations', SCOPE_OPTIONS), url: '/settings/integration', activePaths: ['/settings/integration'] },
        { name: t('actions.payment', SCOPE_OPTIONS), url: '/settings/payment-methods', activePaths: ['/settings/payment-methods'] },
        { name: t('actions.profile', SCOPE_OPTIONS), url: '/settings', activePaths: ['/settings'] },
      ],
      activePaths: ['/settings', '/branches/edit', '/settings/integration', '/settings/payment-methods'],
    },
    {
      key: 'contactForms',
      Icon: Icons.CircleQuestion,
      url: '/contact-forms',
      detail: [
        {
          name: t('actions.contactForms', SCOPE_OPTIONS),
          url: '/contact-forms',
          activePaths: ['/contact-forms'],
        },
      ],
      activePaths: ['/contact-forms', '/contact-forms/[id]'],
    },
  ];

  useEffect(() => {
    const activePath = ROUTES.find((route) => route.activePaths.includes(router.pathname));
    if (activePath) {
      setActiveRoute(activePath);
    }
  }, [router, locale]);

  function isActive(paths: string[]) {
    if (!Array.isArray(paths)) {
      return false;
    }

    return paths.includes(router.pathname);
  }

  function shouldRoute(url: string) {
    if (url === '/media-library' && !company?.feature_flags?.has_media_library_feature) {
      return true;
    }

    if (url === '/social-media-management' && !company?.feature_flags?.has_social_media_feature) {
      return true;
    }

    if (
      !(url === '/settings' || url === '/branches/edit' || url === '/settings/integration') &&
      !company?.feature_flags?.has_campaign_feature &&
      company?.feature_flags?.has_file_manager_feature
    ) {
      return true;
    }

    if (url === '/wallet' && !company?.feature_flags?.has_centric_payment_feature) {
      return true;
    }

    if (url === '/wallet/subscriptions' && !company?.feature_flags?.has_subscription_feature) {
      return true;
    }

    if (url === '/settings/payment-methods' && company?.feature_flags?.has_centric_payment_feature) {
      return true;
    }

    if (url === '/contact-forms' && !company?.feature_flags?.has_contact_forms_feature) {
      return true;
    }

    if (url === '/leadgen' && !company?.feature_flags?.has_lead_generation_feature) {
      return true;
    }

    return false;
  }

  return (
    <div className={cn('fixed top-0 left-0 flex bg-white w-full h-full', className)}>
      <div className="flex w-30 shadow-xl flex-col h-full">
        <div className="h-20 flex flex-col justfiy-center items-center p-5">
          {company && <Logo miniSidebar={miniSidebar} company={company} />}
        </div>
        <div className="w-full flex-1 overflow-y-auto !overflow-x-hidden scrollbar">
          <div className="flex justify-center w-full">
            <ul className="items-center ">
              {ROUTES.map(({ Icon, ...route }) =>
                shouldRoute(route.url) ? (
                  ''
                ) : (
                  <li key={route.key}>
                    <Link href={route.url}>
                      <a
                        className={cn('flex flex-col text-black-800 items-center px-4 py-2 hover:text-gray-700', {
                          'px-0 justify-center': miniSidebar,
                          '!text-blue-600': isActive(route.activePaths),
                        })}
                      >
                        <div className="flex items-center">
                          {route.activePaths.includes(router.pathname) && !miniSidebar && (
                            <span className="left-0 absolute w-0.5 h-16 p-1 bg-blue-600 rounded-r-md"></span>
                          )}

                          <div className="flex flex-col items-center ">
                            <Icon className={cn('min-w-6 min-h-6 w-6 h-6', { '!mr-0': miniSidebar })} />
                            {miniSidebar || (
                              <span className="text-xs mt-2 font-semibold text-center">{t(`actions.${route.key}`, SCOPE_OPTIONS)}</span>
                            )}
                          </div>
                        </div>
                      </a>
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Preferences miniSidebar={miniSidebar} />
        </div>
      </div>

      {miniSidebar || (
        <div className="w-64 relative overflow-hidden">
          <div className="flex items-center justify-center border-b h-18 p-5 mb-5">
            {activeRoute && (
              <div className="flex items-center justify-center font-semibold space-x-2.5 text-black-800 ">
                <activeRoute.Icon className={cn('min-w-5 min-h-5 w-5 h-5')} />
                <span className="text-sm">{t(`actions.${activeRoute?.key}`, SCOPE_OPTIONS)}</span>
              </div>
            )}
          </div>

          {activeRoute &&
            activeRoute?.detail?.map((route) => {
              if (shouldRoute(route.url)) {
                return;
              }
              return (
                <React.Fragment key={route.url}>
                  <Link href={route.url}>
                    <a
                      className={cn('flex items-center mb-2.5 px-4 w-full text-gray-500 group', {
                        'text-white font-semibold': isActive(route.activePaths),
                      })}
                    >
                      {route.url === '/campaigns/pending' ||
                      route.url === '/social-media-management' ||
                      route.url === '/social-media-management/[id]' ? (
                        <div
                          className={cn(
                            'flex items-center justify-center w-full text-xs px-1 py-0.5 rounded-lg transition-all bg-green-600 text-white font-semibold'
                          )}
                        >
                          <div className={cn('text-white mr-0.5 transition-all')}>
                            <Icons.Plus className="w-3 h-3" />
                          </div>

                          <div className="p-2">{route.name}</div>
                        </div>
                      ) : (
                        <div
                          className={cn(
                            'flex items-center justify-between w-full text-xs px-1 py-0.5 bg-gray-200 rounded-lg transition-all',
                            {
                              '!bg-blue-600 text-white font-semibold': isActive(route.activePaths),
                              'group-hover:text-gray-600': !isActive(route.activePaths),
                            }
                          )}
                        >
                          <div className="p-2">{route.name}</div>

                          <div
                            className={cn('text-gray-200 mr-0.5 transition-all', {
                              'group-hover:text-gray-600': !isActive(route.activePaths),
                            })}
                          >
                            {<Icons.ChevronRight className="w-3 h-3" />}
                          </div>
                        </div>
                      )}
                    </a>
                  </Link>
                </React.Fragment>
              );
            })}

          <Collapse miniSidebar={miniSidebar} onCollapseClick={onCollapseClick} />
        </div>
      )}
      {miniSidebar && <Collapse miniSidebar={miniSidebar} onCollapseClick={onCollapseClick} />}
    </div>
  );
};

export default Sidebar;
