/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CustomVariableCampaignImageInputVariables
 */
export interface CustomVariableCampaignImageInputVariables {
    /**
     * 
     * @type {string}
     * @memberof CustomVariableCampaignImageInputVariables
     */
    key?: CustomVariableCampaignImageInputVariablesKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomVariableCampaignImageInputVariables
     */
    value?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomVariableCampaignImageInputVariablesKeyEnum {
    BranchName = 'BRANCH_NAME',
    BranchAddress = 'BRANCH_ADDRESS',
    BranchPhone = 'BRANCH_PHONE',
    BranchWebsite = 'BRANCH_WEBSITE',
    AddressDirection = 'ADDRESS_DIRECTION',
    VarPrice = 'VAR_PRICE',
    BranchCity = 'BRANCH_CITY',
    BranchDistrict = 'BRANCH_DISTRICT'
}



