import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaImagesBranchApi, CompanyCampaignsBranchApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function showBranchCanvaImage(branchId: string, canvaImageId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaImagesBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCanvaImage(branchId, canvaImageId).then((res) => res.data);
}

export default function useShowBranchCanvaImage({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useShowCampaignsOfCompany', branchId, params.canvaImageId],
    () => showBranchCanvaImage(branchId, params.canvaImageId, axiosInstance),
    props
  );
}
