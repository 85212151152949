/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { InsightListResponseCover } from './insight-list-response-cover';
import { InsightListResponseInsights1 } from './insight-list-response-insights1';

/**
 * 
 * @export
 * @interface InsightListResponseCampaigns
 */
export interface InsightListResponseCampaigns {
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    targeting_type: InsightListResponseCampaignsTargetingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    campaign_type: InsightListResponseCampaignsCampaignTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    name: string;
    /**
     * 
     * @type {InsightListResponseCover}
     * @memberof InsightListResponseCampaigns
     */
    cover?: InsightListResponseCover;
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof InsightListResponseCampaigns
     */
    status: InsightListResponseCampaignsStatusEnum;
    /**
     * 
     * @type {Array<InsightListResponseInsights1>}
     * @memberof InsightListResponseCampaigns
     */
    insights: Array<InsightListResponseInsights1>;
}

/**
    * @export
    * @enum {string}
    */
export enum InsightListResponseCampaignsTargetingTypeEnum {
    WhatsappMessage = 'WHATSAPP_MESSAGE',
    Messenger = 'MESSENGER',
    CallNow = 'CALL_NOW',
    FinalUrl = 'FINAL_URL',
    InstagramVisit = 'INSTAGRAM_VISIT',
    LeadGeneration = 'LEAD_GENERATION',
    AndroidAppInstalls = 'ANDROID_APP_INSTALLS',
    IosAppInstalls = 'IOS_APP_INSTALLS',
    OutcomeAwareness = 'OUTCOME_AWARENESS',
    OutcomeSales = 'OUTCOME_SALES',
    GetDirections = 'GET_DIRECTIONS',
    Pmax = 'PMAX',
    Smart = 'SMART'
}
/**
    * @export
    * @enum {string}
    */
export enum InsightListResponseCampaignsCampaignTypeEnum {
    Google = 'GOOGLE',
    Facebook = 'FACEBOOK',
    Dynamiclocationads = 'DYNAMICLOCATIONADS'
}
/**
    * @export
    * @enum {string}
    */
export enum InsightListResponseCampaignsStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Running = 'running',
    Rejected = 'rejected',
    Finished = 'finished',
    Paused = 'paused',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Archived = 'archived',
    CancelledPermanently = 'cancelled_permanently'
}



