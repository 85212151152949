import { Icons } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';
import { PUBLISHER_PLATFORMS } from '@app/constants';

type CardImagePropTypes = {
  name: string;
  image: string | undefined;
  platforms?: string[];
};

const CardImage: React.FC<CardImagePropTypes> = ({ image, platforms, name }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.Card.CardImage',
  };
  const { branch } = useAuth();
  const { t } = useLocale();
  const isGooglePlatform = (platforms as string[])?.includes(PUBLISHER_PLATFORMS.GOOGLE);

  return (
    <>
      {isGooglePlatform ? (
        <div className="my-3 rounded-t-2xl aspect-1 object-contain bg-gray-200 flex flex-col">
          <img src="/images/map-dummy-image.png" className="rounded-t-2xl object-cover bg-black-800 h-full" alt={name} />

          <div className="bg-white pt-2">
            <div className="items-center whitespace-nowrap overflow-hidden text-ellipsis">
              <span className="text-sm font-semibold mr-2">{branch?.name}</span>
              <span className="text-sm text-gray-500 font-thin">{t('labels.bussinessProfile', SCOPE_OPTIONS)}</span>
            </div>
            <div className="flex items-center py-2">
              <div className="rounded-5 border-[0.1rem] border-gray-200 w-auto flex justify-center items-center py-1 px-2 text-blue-500 mr-1">
                <Icons.CallNow className="w-3 h-3 mr-1.5" />
                <span className="text-3 block line-clamp-1">{t('labels.callNow', SCOPE_OPTIONS)}</span>
              </div>
              <div className="flex items-center">
                <div className="rounded-5 border-[0.1rem] border-gray-200 w-auto flex justify-center items-center py-1 px-2 text-blue-500">
                  <Icons.Directions className="w-3 h-3 mr-1.5" />
                  <span className="text-3 block line-clamp-1">{t('labels.getDirections', SCOPE_OPTIONS)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img src={image} className="my-3 rounded-2xl aspect-1 object-contain" alt={name} />
      )}
    </>
  );
};

export default CardImage;
