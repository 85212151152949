/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CampaignListResponse } from '../model';
/**
 * CompanyCampaignsBranchApi - axios parameter creator
 * @export
 */
export const CompanyCampaignsBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return active campaigns of company
         * @summary Return active campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveCampaignsOfCompany: async (branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listActiveCampaignsOfCompany', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/company_campaigns`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return pending campaigns of company
         * @summary Return pending campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingCampaignsOfCompany: async (branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listPendingCampaignsOfCompany', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/company_campaigns/pending`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return pending videos of company
         * @summary Return pending videos of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingVideosOfCompany: async (branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listPendingVideosOfCompany', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/company_campaigns/pending_videos`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return active campaigns of company by id
         * @summary Return active campaigns of company by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaignsOfCompany: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showCampaignsOfCompany', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showCampaignsOfCompany', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/company_campaigns/{campaign_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyCampaignsBranchApi - functional programming interface
 * @export
 */
export const CompanyCampaignsBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyCampaignsBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Return active campaigns of company
         * @summary Return active campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveCampaignsOfCompany(branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return pending campaigns of company
         * @summary Return pending campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPendingCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPendingCampaignsOfCompany(branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return pending videos of company
         * @summary Return pending videos of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPendingVideosOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPendingVideosOfCompany(branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return active campaigns of company by id
         * @summary Return active campaigns of company by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showCampaignsOfCompany(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showCampaignsOfCompany(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyCampaignsBranchApi - factory interface
 * @export
 */
export const CompanyCampaignsBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyCampaignsBranchApiFp(configuration)
    return {
        /**
         * Return active campaigns of company
         * @summary Return active campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse> {
            return localVarFp.listActiveCampaignsOfCompany(branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return pending campaigns of company
         * @summary Return pending campaigns of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse> {
            return localVarFp.listPendingCampaignsOfCompany(branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return pending videos of company
         * @summary Return pending videos of company
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingVideosOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse> {
            return localVarFp.listPendingVideosOfCompany(branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return active campaigns of company by id
         * @summary Return active campaigns of company by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaignsOfCompany(branchId: string, campaignId: string, options?: any): AxiosPromise<object> {
            return localVarFp.showCampaignsOfCompany(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyCampaignsBranchApi - interface
 * @export
 * @interface CompanyCampaignsBranchApi
 */
export interface CompanyCampaignsBranchApiInterface {
    /**
     * Return active campaigns of company
     * @summary Return active campaigns of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApiInterface
     */
    listActiveCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse>;

    /**
     * Return pending campaigns of company
     * @summary Return pending campaigns of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApiInterface
     */
    listPendingCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse>;

    /**
     * Return pending videos of company
     * @summary Return pending videos of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApiInterface
     */
    listPendingVideosOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse>;

    /**
     * Return active campaigns of company by id
     * @summary Return active campaigns of company by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApiInterface
     */
    showCampaignsOfCompany(branchId: string, campaignId: string, options?: any): AxiosPromise<object>;

}

/**
 * CompanyCampaignsBranchApi - object-oriented interface
 * @export
 * @class CompanyCampaignsBranchApi
 * @extends {BaseAPI}
 */
export class CompanyCampaignsBranchApi extends BaseAPI implements CompanyCampaignsBranchApiInterface {
    /**
     * Return active campaigns of company
     * @summary Return active campaigns of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApi
     */
    public listActiveCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CompanyCampaignsBranchApiFp(this.configuration).listActiveCampaignsOfCompany(branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return pending campaigns of company
     * @summary Return pending campaigns of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApi
     */
    public listPendingCampaignsOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CompanyCampaignsBranchApiFp(this.configuration).listPendingCampaignsOfCompany(branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return pending videos of company
     * @summary Return pending videos of company
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApi
     */
    public listPendingVideosOfCompany(branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CompanyCampaignsBranchApiFp(this.configuration).listPendingVideosOfCompany(branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return active campaigns of company by id
     * @summary Return active campaigns of company by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyCampaignsBranchApi
     */
    public showCampaignsOfCompany(branchId: string, campaignId: string, options?: any) {
        return CompanyCampaignsBranchApiFp(this.configuration).showCampaignsOfCompany(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }
}
