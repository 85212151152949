import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaGenerateTextBranchApi } from '@app/api';
import type { GenerateTextInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createBranchCampaign(
  branchId: string,
  canvaTemplateId: string,
  generateTextInput: GenerateTextInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaGenerateTextBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateCanvaTemplateText(branchId, canvaTemplateId, generateTextInput).then((res) => res.data);
}

export default function useCreateMagicText(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; canvaTemplateId: string; branchCampaignCreateInput: GenerateTextInput }) =>
      createBranchCampaign(values.branchId, values.canvaTemplateId, values.branchCampaignCreateInput, axiosInstance),
    props
  );
}
