import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { CAMPAIGN_TYPES, CALL_TO_ACTION, DESTINATION_TYPES, TARGETING_TYPES, ICON_TYPE, IMAGE_PLACEMENT_TYPE } from '@app/constants';
import { Box, Button, Form, Icons, Location } from '@app/components';
import { BranchCanvaImage } from '@app/api';
import { api, useAuth, useLocale } from '@app/hooks';
import { url as urlLib, phoneNumber } from '@app/lib';

import ObjectiveFormPicker from './ObjectiveFormPicker';

type ObjectiveFormPropTypes = {
  canvaImage: BranchCanvaImage;
  isEdit?: boolean;
};

type LocationObjectType = {
  lat: number;
  lng: number;
};

const ObjectiveForm: React.FC<ObjectiveFormPropTypes> = ({ canvaImage, isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ObjectiveForm',
  };
  const { t } = useLocale();
  const {
    watch,
    setValue,
    register,
    unregister,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { branch, branchId } = useAuth();
  const campaignType = watch('campaignType');
  const phoneNumberWatch = watch('phoneNumber');
  const [selectedSubOption, setSelectedSubOption] = useState(true);
  const { data: pages } = api.useGetCurrentFacebookPageResponse({
    params: {
      branchId: branchId,
    },
  });
  const { mutate: headlineMutate } = api.useCreateMagicText({
    onSuccess: (data: any) => {
      setValue('headline', (data.generated_text[0].content as string).replaceAll('"', ''));
    },
  });
  const { mutate: messageMutate } = api.useCreateMagicText({
    onSuccess: (data: any) => {
      setValue('message', (data.generated_text[0].content as string).replaceAll('"', ''));
    },
  });
  const { mutate: descriptionMutate } = api.useCreateMagicText({
    onSuccess: (data: any) => {
      setValue('description', (data.generated_text[0].content as string).replaceAll('"', ''));
    },
  });

  const instagramPage = pages?.instagram_account;
  const hasWhatsappNumber = !!pages?.has_whatsapp_business_number;
  const hasInstagramPage = pages?.instagram_account ? true : false;
  const allMessageValue = campaignType === CAMPAIGN_TYPES.WHATSAPP_MESSAGE ? CAMPAIGN_TYPES.WHATSAPP_MESSAGE : CAMPAIGN_TYPES.MESSENGER;

  useEffect(() => {
    if (phoneNumberWatch) {
      const phoneUri = phoneNumber.uri(phoneNumberWatch);

      handleSelect(CAMPAIGN_TYPES.CALL, phoneUri ?? '');
    }
  }, [phoneNumberWatch]);

  useEffect(() => {
    if (campaignType !== CAMPAIGN_TYPES.FINAL_URL) {
      unregister('url');
    }

    if (campaignType !== CAMPAIGN_TYPES.CALL) {
      unregister('phoneNumber');
    }

    if (campaignType !== CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
      unregister('instagramUsername');
    }

    if (campaignType) {
      clearErrors('campaignType');
    }
  }, [campaignType]);

  const TARGET_OPTIONS = () =>
    [
      {
        label: t('labels.allMessage', SCOPE_OPTIONS),
        value: allMessageValue,
        disabled: false,
        Icon: ICON_TYPE.ALL_MESSAGE,
      },
      {
        label: t('labels.web', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.FINAL_URL,
        disabled: false,
        Icon: ICON_TYPE.FINAL_URL,
      },
      {
        label: t('labels.call', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.CALL,
        disabled: canvaImage?.placement_type === IMAGE_PLACEMENT_TYPE.STORY,
        Icon: ICON_TYPE.CALL,
      },
      {
        label: t('labels.profileVisit', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.INSTAGRAM_VISIT,
        disabled: false,
        Icon: ICON_TYPE.PERSON,
      },
      {
        label: t('labels.getDirections', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.GET_DIRECTIONS,
        Icon: ICON_TYPE.LOCATION,
        disabled: false,
      },
    ].map((option) => {
      // if (isEdit) {
      //   if (option.value === foundedCampaignType) {
      //     return { ...option, disabled: false };
      //   }

      //   return { ...option, disabled: true };
      // }

      if (option.value === CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
        if (hasInstagramPage === false) {
          return { ...option, disabled: true };
        }
      }

      if (option.value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
        if (hasWhatsappNumber === false) {
          return { ...option, disabled: true };
        }
      }

      return option;
    });

  const OPTIONS = () =>
    [
      {
        label: t('campaignTypes.whatsappMessage'),
        value: CAMPAIGN_TYPES.WHATSAPP_MESSAGE,
        disabled: false,
        Icon: ICON_TYPE.WHATSAPP_MESSAGE,
      },
      {
        label: t('campaignTypes.messenger'),
        value: CAMPAIGN_TYPES.MESSENGER,
        disabled: false,
        Icon: ICON_TYPE.MESSENGER,
      },
    ].map((option) => {
      // if (isEdit) {
      //   if (option.value === foundedCampaignType) {
      //     return { ...option, disabled: false };
      //   }

      //   return { ...option, disabled: true };
      // }

      if (option.value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
        if (hasWhatsappNumber === false) {
          return { ...option, disabled: true };
        }
      }

      return option;
    });

  function getWebsite(fallback: string) {
    if (branch?.website || branch?.website === '') {
      return fallback;
    }

    return branch?.website;
  }

  function handleSelect(value: string, extra = '') {
    setValue('campaignType', value);

    if (value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
      setValue('destinationType', DESTINATION_TYPES.WHATSAPP);
      setValue('targetingType', TARGETING_TYPES.WHATSAPP_MESSAGE);
      setValue('linkData', {
        link: getWebsite('https://api.whatsapp.com/send'),
        call_to_action: {
          type: CALL_TO_ACTION.WHATSAPP_MESSAGE,
          value: {
            app_destination: DESTINATION_TYPES.WHATSAPP,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.MESSENGER) {
      setValue('destinationType', DESTINATION_TYPES.MESSENGER);
      setValue('targetingType', TARGETING_TYPES.MESSENGER);
      setValue('linkData', {
        link: extra.length === 0 ? branch.website : extra,
        call_to_action: {
          type: CALL_TO_ACTION.MESSAGE_PAGE,
          value: {
            app_destination: DESTINATION_TYPES.MESSENGER,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.CALL) {
      setValue('destinationType', DESTINATION_TYPES.PHONE_CALL);
      setValue('targetingType', TARGETING_TYPES.CALL_NOW);
      setValue('linkData', {
        link: getWebsite(`https://fb.me/`),
        call_to_action: {
          type: CALL_TO_ACTION.CALL_NOW,
          value: {
            link: extra,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.FINAL_URL) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.FINAL_URL);
      setValue('linkData', {
        link: extra,
        call_to_action: {
          type: CALL_TO_ACTION.LEARN_MORE,
        },
      });
    }

    if (value === CAMPAIGN_TYPES.LEAD_GENERATION) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.LEAD_GENERATION);
    }

    if (value === CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.INSTAGRAM_VISIT);
      setValue('linkData', {
        link: `https://www.instagram.com/${instagramPage?.username}`,
        call_to_action: {
          type: CALL_TO_ACTION.LEARN_MORE,
        },
      });
    }

    if (value === CAMPAIGN_TYPES.GET_DIRECTIONS) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.GET_DIRECTIONS);
      setValue('linkData', {
        link: `https://www.google.com/maps/dir//${branch?.location?.latitude},${branch?.location?.longitude}`,
        call_to_action: {
          type: CALL_TO_ACTION.GET_DIRECTIONS,
        },
      });
    }
  }

  function createMagicText(type: string) {
    if (type === 'headline') {
      headlineMutate({
        branchId: branchId,
        canvaTemplateId: canvaImage.canva_template_id,
        branchCampaignCreateInput: {
          generate_text_params: {
            generate_type: 'text_content',
            max_char: 30,
            contents: [],
            extra: [],
          },
        },
      });
    }
    if (type === 'message') {
      messageMutate({
        branchId: branchId,
        canvaTemplateId: canvaImage.canva_template_id,
        branchCampaignCreateInput: {
          generate_text_params: {
            generate_type: 'text_content',
            max_char: 30,
            contents: [],
            extra: [],
          },
        },
      });
    }
    if (type === 'description') {
      descriptionMutate({
        branchId: branchId,
        canvaTemplateId: canvaImage.canva_template_id,
        branchCampaignCreateInput: {
          generate_text_params: {
            generate_type: 'text_content',
            max_char: 255,
            contents: [],
            extra: [],
          },
        },
      });
    }
  }

  function getLocation(): LocationObjectType {
    if (!branch?.location?.latitude || !branch?.location?.longitude) {
      return { lat: 0, lng: 0 };
    }

    return {
      lat: Number(branch?.location?.latitude),
      lng: Number(branch?.location?.longitude),
    };
  }

  useEffect(() => {
    if (
      campaignType === CAMPAIGN_TYPES.ALL_MESSAGE ||
      campaignType === CAMPAIGN_TYPES.WHATSAPP_MESSAGE ||
      campaignType === CAMPAIGN_TYPES.MESSENGER
    ) {
      setSelectedSubOption(true);
    }

    if (
      campaignType !== CAMPAIGN_TYPES.ALL_MESSAGE &&
      campaignType !== CAMPAIGN_TYPES.WHATSAPP_MESSAGE &&
      campaignType !== CAMPAIGN_TYPES.MESSENGER
    ) {
      setSelectedSubOption(false);
    }
  }, [campaignType]);

  return (
    <>
      <div className="mb-4 mt-8">
        <span className="block text-3.5 font-semibold text-gray-900 mb-4 text-lg">{t('title.target', SCOPE_OPTIONS)}</span>
        <ObjectiveFormPicker
          name="campaignType"
          className="grid grid-cols-3 gap-3 mt-2 "
          options={TARGET_OPTIONS().filter((item) => item.disabled === false)}
          value={campaignType}
          onChange={handleSelect}
          hasMainOption
          control={control}
          rules={{
            required: { value: true, message: t('form.campaignType.errors.required', SCOPE_OPTIONS) },
          }}
          error={errors.campaignType}
        />
      </div>
      {selectedSubOption && (
        <div className="mb-4">
          <span className="block text-3.5 font-semibold text-black-800 text-lg mb-4"> {t('title.appMessage', SCOPE_OPTIONS)}</span>
          <ObjectiveFormPicker
            name="campaignType"
            className="grid grid-cols-3 gap-3 mt-2"
            options={OPTIONS()}
            value={campaignType}
            onChange={handleSelect}
          />
        </div>
      )}

      {campaignType === CAMPAIGN_TYPES.FINAL_URL && (
        <>
          <span className="text-lg font-semibold"> {t('form.url.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.Input
              type="text"
              id="url"
              placeholder={t('form.url.placeholder', SCOPE_OPTIONS)}
              {...register('url', {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSelect(CAMPAIGN_TYPES.FINAL_URL, e.target.value);
                },
                required: { value: true, message: t('form.url.errors.required', SCOPE_OPTIONS) },
                validate: (url) => {
                  if (urlLib.isValid(url)) {
                    return true;
                  }

                  return t('form.url.errors.validate', SCOPE_OPTIONS);
                },
              })}
              disabled={isEdit}
              error={errors.url}
              Icon="Website"
            />
          </Box>
        </>
      )}
      {campaignType === CAMPAIGN_TYPES.MESSENGER && (
        <>
          <span className="text-lg font-semibold">{t('form.website.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.Input
              type="text"
              id="website"
              placeholder={t('form.website.placeholder', SCOPE_OPTIONS)}
              {...register('website', {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSelect(CAMPAIGN_TYPES.MESSENGER, e.target.value);
                },
                required: { value: true, message: t('form.website.errors.required', SCOPE_OPTIONS) },
                validate: (url) => {
                  if (urlLib.isValid(url)) {
                    return true;
                  }

                  return t('form.website.errors.validate', SCOPE_OPTIONS);
                },
              })}
              disabled={isEdit}
              defaultValue={branch.website}
              error={errors.website}
              Icon="Messenger"
            />
          </Box>
        </>
      )}

      {campaignType === CAMPAIGN_TYPES.CALL && (
        <>
          <span className="text-lg font-semibold mt-6"> {t('form.phoneNumber.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.PhoneInput
              className="mb-4.5"
              control={control}
              name="phoneNumber"
              id="phoneNumber"
              rules={{
                required: { value: true, message: t('form.phoneNumber.errors.required', SCOPE_OPTIONS) },
                validate: (phoneNumberString: string) => {
                  if (phoneNumber.isValid(phoneNumberString)) {
                    return true;
                  }

                  return t('form.phoneNumber.errors.validate', SCOPE_OPTIONS);
                },
              }}
              disabled={true}
              error={errors.phoneNumber}
              defaultValue={branch.phone_number}
            />
          </Box>
        </>
      )}
      {campaignType === CAMPAIGN_TYPES.GET_DIRECTIONS && (
        <>
          <span className="text-lg font-semibold">{t('form.getDirections.label', SCOPE_OPTIONS)}</span>
          <Location.LoadScript>
            <div className="bg-white p-5 rounded-3.5 shadow-sm">
              <div className="mb-3 h-72 rounded-2.5 overflow-hidden">
                <Location.Picker autoLocation={false} value={getLocation()} onChange={() => null} />
              </div>
            </div>
          </Location.LoadScript>
        </>
      )}
      <div className="w-full h-[1px] bg-black-800 my-4 opacity-70"></div>
      <div className="flex flex-col">
        <div className="flex flex-col w-full">
          <span className="text-lg font-semibold">Paylaşım Metni</span>
          <span className="text-sm opacity-40">Akış Görselini paylaşırken açıklama için yazmak istediğiniz metni buraya yazınız.</span>
        </div>
        <Form.Textarea
          className="mb-4.5"
          id="description"
          {...register('description', {
            required: {
              value: true,
              message: t('errors.required', SCOPE_OPTIONS),
            },
          })}
          error={errors.description}
          maxLength={255}
          length={watch('description')?.length}
          resizeOff={true}
          rows={4}
        />
        <Button
          onClick={() => createMagicText('description')}
          className="ml-auto"
          IconLeft={Icons.Stick}
          theme="green"
          label="Sihirli Yazı Oluştur"
          block={false}
          size="sm"
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col w-full">
          <span className="text-lg font-semibold">Başlık</span>
        </div>
        <Form.Input
          type="text"
          className="mb-4.5"
          id="headline"
          {...register('headline', {
            required: {
              value: true,
              message: t('errors.required', SCOPE_OPTIONS),
            },
          })}
          error={errors.headline}
        />
        <Button
          onClick={() => createMagicText('headline')}
          className="ml-auto"
          IconLeft={Icons.Stick}
          theme="green"
          label="Sihirli Yazı Oluştur"
          block={false}
          size="sm"
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col w-full">
          <span className="text-lg font-semibold">Açıklama</span>
        </div>
        <Form.Input
          type="text"
          className="mb-4.5"
          id="message"
          {...register('message', {
            required: {
              value: true,
              message: t('errors.required', SCOPE_OPTIONS),
            },
          })}
          error={errors.message}
        />
        <Button
          onClick={() => createMagicText('message')}
          className="ml-auto"
          IconLeft={Icons.Stick}
          theme="green"
          label="Sihirli Yazı Oluştur"
          block={false}
          size="sm"
        />
      </div>
    </>
  );
};

export default ObjectiveForm;
