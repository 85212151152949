const TARGETING_TYPES = Object.freeze({
  FINAL_URL: 'FINAL_URL',
  APP: 'APP',
  MESSENGER: 'MESSENGER',
  INSTAGRAM_VISIT: 'INSTAGRAM_VISIT',
  WHATSAPP_MESSAGE: 'WHATSAPP_MESSAGE',
  CALL_NOW: 'CALL_NOW',
  LEAD_GENERATION: 'LEAD_GENERATION',
  PMAX: 'PMAX',
  SMART: 'SMART',
  GET_DIRECTIONS: 'GET_DIRECTIONS',
});

export default TARGETING_TYPES;
