import { Fragment } from 'react';

import { useFormContext } from 'react-hook-form';
import { Tab } from '@headlessui/react';
import cn from 'classnames';

import { number } from '@app/lib';
import { useLocale } from '@app/hooks';
import { Box, Form } from '@app/components';
import { GENDERS, LOCALES, TAB_VALUES } from '@app/constants';

import InterestInput from './InterestInput';
import CanvaLocationForm from './CanvaLocationForm';
import DateForm from './DateForm';

const CanvaGmSettingsTab: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm',
  };
  const { t } = useLocale();
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const ageMinWatch = watch('ageMin');
  const ageMaxWatch = watch('ageMax');

  const tabName = [
    { name: t('GmSettingsTab.labels.time', SCOPE_OPTIONS), value: TAB_VALUES.TIME, key: 0, display: true },
    { name: t('GmSettingsTab.labels.locations', SCOPE_OPTIONS), value: TAB_VALUES.LOCATION, key: 1, display: true },
    { name: t('GmSettingsTab.labels.targeting', SCOPE_OPTIONS), value: TAB_VALUES.TARGETING, key: 2, display: true },
  ];

  const gendersMap = Object.values(GENDERS).map((gender) => ({
    label: t(`genders.${gender.toLocaleLowerCase()}`),
    value: gender,
  }));

  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex items-center rounded-xl p-1">
          {tabName.map(
            (category) =>
              category.display && (
                <Tab as={Fragment} key={category.key}>
                  {({ selected }) => (
                    <button
                      className={cn(
                        'w-full py-2.5 text-lg font-semibold leading-5 text-gray-500 border-b-0.5 flex items-center justify-center  border-gray-500 hover:text-gray-600 ',
                        { 'border-blue-600 text-blue-600 hover:text-blue-600': selected }
                      )}
                    >
                      {category.name}
                    </button>
                  )}
                </Tab>
              )
          )}
        </Tab.List>

        <Tab.Panels>
          {tabName.map(
            (category) =>
              category.display && (
                <Tab.Panel key={category.key}>
                  {category.value === TAB_VALUES.TARGETING && (
                    <>
                      <Box className="bg-white px-5 mt-5">
                        <div className="grid grid-cols-4 gap-4">
                          <Form.Select
                            name="gender"
                            label={t('form.gender.label', SCOPE_OPTIONS)}
                            rules={{
                              required: { value: true, message: t('form.gender.errors.required', SCOPE_OPTIONS) },
                            }}
                            control={control}
                            options={gendersMap}
                            error={errors.gender}
                            className="mb-4 border-none"
                          />
                          <div>
                            <Form.Select
                              isMulti
                              name="locales"
                              label={'Diller'}
                              // rules={{
                              //   required: { value: true, message: t('form.locales.errors.required', SCOPE_OPTIONS) },
                              // }}
                              control={control}
                              options={LOCALES.map(({ name, key }) => ({
                                value: key,
                                label: name,
                              }))}
                              error={errors.ageMax}
                              defaultValue={[{ label: 'English (All)', value: 1001 }]}
                            />
                          </div>
                          <div>
                            <Form.Select
                              name="ageMin"
                              label={t('form.ageMin.label', SCOPE_OPTIONS)}
                              rules={{
                                required: { value: true, message: t('form.ageMin.errors.required', SCOPE_OPTIONS) },
                                min: { value: 18, message: t('form.ageMin.errors.min', { ...SCOPE_OPTIONS, value: 18 }) },
                                validate: (age: number) => {
                                  if (age <= ageMaxWatch) {
                                    return true;
                                  }

                                  return t('form.ageMin.errors.max', SCOPE_OPTIONS);
                                },
                              }}
                              control={control}
                              options={number.generateInRange(18, 65).map((index) => ({
                                value: index,
                                label: String(index),
                              }))}
                              error={errors.ageMin}
                            />
                          </div>
                          <div>
                            <Form.Select
                              name="ageMax"
                              label={t('form.ageMax.label', SCOPE_OPTIONS)}
                              rules={{
                                required: { value: true, message: t('form.ageMax.errors.required', SCOPE_OPTIONS) },
                                max: { value: 65, message: t('form.ageMax.errors.max', { ...SCOPE_OPTIONS, value: 65 }) },
                                validate: (age: number) => {
                                  if (age >= ageMinWatch) {
                                    return true;
                                  }

                                  return t('form.ageMax.errors.min', SCOPE_OPTIONS);
                                },
                              }}
                              control={control}
                              options={number.generateInRange(18, 65).map((index) => ({
                                value: index,
                                label: String(index),
                              }))}
                              error={errors.ageMax}
                            />
                          </div>
                        </div>

                        <InterestInput defaultValue={[]} />
                      </Box>
                    </>
                  )}
                  {category.value === TAB_VALUES.LOCATION && <CanvaLocationForm />}
                  {category.value === TAB_VALUES.TIME && <DateForm />}
                </Tab.Panel>
              )
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
export default CanvaGmSettingsTab;
