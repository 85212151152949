import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { InvoiceInformationsBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listInvoinceInformations(branchId: string, axiosInstance: AxiosInstance) {
  const api = new InvoiceInformationsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.listBranchInvoiceInformation(branchId).then((res) => res.data);
}

export default function useListInvoinceInformations() {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(['listInvoiceInformation', branchId], () => listInvoinceInformations(branchId, axiosInstance));
}
