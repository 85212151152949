import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_15_83)">
      <path
        d="M37.4482 25.2246C36.391 25.7451 35.3491 26.3027 34.2701 26.7723C33.5738 27.0754 33.1302 27.5254 32.8288 28.2203C32.4171 29.1712 31.9132 30.0813 31.4478 31.0088C31.3948 31.1142 31.3374 31.2175 31.2583 31.3665C30.6731 30.1976 30.0886 29.0883 29.5629 27.9506C29.3305 27.4483 29.0045 27.1292 28.5071 26.8966C27.373 26.3645 26.2635 25.7815 25.0981 25.197C25.2717 25.104 25.4024 25.0305 25.5367 24.9629C26.6251 24.4177 27.7113 23.8667 28.8056 23.3332C29.0786 23.2001 29.2659 23.0242 29.401 22.7458C29.9477 21.6169 30.5155 20.4974 31.0768 19.375C31.1291 19.2703 31.1879 19.1686 31.2677 19.0203C31.4238 19.3256 31.5618 19.5902 31.6947 19.8577C32.1964 20.8609 32.6923 21.8677 33.2013 22.8672C33.2674 22.9973 33.3763 23.1376 33.5012 23.2016C34.7777 23.8558 36.0621 24.4955 37.3444 25.1374C37.3734 25.1519 37.4126 25.1476 37.4475 25.1519V25.2246H37.4482Z"
        fill="currentColor"
      />
      <path
        d="M28.769 15.8682C28.7073 15.9336 28.6434 16.0056 28.5752 16.0732C21.9053 22.75 15.2376 29.4284 8.56409 36.1009C6.09828 38.5666 1.92544 37.6667 0.762239 34.3955C0.111661 32.5665 0.439855 30.8532 1.81652 29.4676C8.50237 22.7391 15.2144 16.0368 21.9176 9.32505C21.9764 9.26617 22.0418 9.21383 22.0839 9.17603C24.3116 11.4063 26.529 13.6263 28.7683 15.8682H28.769ZM15.9012 19.75C15.8294 19.8183 15.7408 19.8997 15.6565 19.9841C13.1973 22.4462 10.7387 24.909 8.28019 27.3719C6.85414 28.7996 5.431 30.2302 4.0006 31.6535C3.53373 32.118 3.33768 32.6625 3.50323 33.3022C3.83506 34.5794 5.37001 34.9232 6.35459 33.9412C10.2537 30.0477 14.1485 26.1506 18.0447 22.2543C18.1115 22.1881 18.1724 22.1161 18.2153 22.0696C17.4427 21.2954 16.6854 20.5365 15.9012 19.7507V19.75ZM20.4458 19.7289C21.7347 18.4386 23.0532 17.1185 24.3348 15.8347C23.5978 15.1013 22.8332 14.3409 22.0919 13.603C20.806 14.8904 19.4939 16.204 18.1913 17.5081C18.9443 18.2496 19.7139 19.0078 20.4466 19.7289H20.4458Z"
        fill="currentColor"
      />
      <path
        d="M6.53662 6.67531C7.11604 6.38454 7.65262 6.11558 8.1892 5.84661C8.92256 5.47951 9.66172 5.12186 10.3864 4.73877C10.5432 4.6559 10.6949 4.50469 10.7755 4.34695C11.4181 3.09154 12.0433 1.82667 12.7048 0.50293C12.7832 0.646135 12.8398 0.741364 12.8892 0.840226C13.4672 1.9946 14.035 3.15405 14.6289 4.30043C14.7247 4.48579 14.907 4.66317 15.0929 4.75985C16.2278 5.34866 17.375 5.91203 18.5171 6.48486C18.6224 6.53792 18.7255 6.59608 18.8722 6.67459C18.6943 6.76909 18.5614 6.84396 18.4249 6.91229C17.3046 7.47421 16.1791 8.02814 15.066 8.60459C14.8903 8.69546 14.7211 8.86702 14.6296 9.04366C14.0589 10.1479 13.5122 11.2644 12.9567 12.3759C12.8877 12.5148 12.8159 12.6529 12.7207 12.8404C12.6387 12.6929 12.5748 12.5882 12.5203 12.4784C11.9424 11.324 11.3724 10.1653 10.7813 9.01749C10.6957 8.85102 10.5338 8.68964 10.3675 8.60387C9.11424 7.95908 7.85085 7.33319 6.53662 6.67459V6.67531Z"
        fill="currentColor"
      />
      <path
        d="M26.6284 5.9009C27.7764 5.32662 28.8684 4.78942 29.9495 4.22968C30.1158 4.1439 30.2741 3.98034 30.3612 3.81315C30.8681 2.83906 31.3516 1.85334 31.8439 0.871978C31.8969 0.765846 31.955 0.662622 32.0385 0.50415C32.2556 0.93086 32.4509 1.31105 32.6419 1.69341C32.9984 2.40581 33.3433 3.12402 33.7143 3.82841C33.7971 3.9847 33.9474 4.13663 34.1042 4.21732C35.1854 4.77561 36.2767 5.31426 37.4297 5.89145C37.2874 5.97141 37.1938 6.02884 37.0957 6.07754C36.1148 6.56968 35.1288 7.05091 34.1572 7.55976C33.9714 7.65717 33.7891 7.83381 33.6925 8.01918C33.1364 9.08922 32.607 10.1731 32.0436 11.3013C31.9688 11.1646 31.9122 11.0672 31.8613 10.9662C31.3698 9.98408 30.8891 8.9969 30.3808 8.02427C30.2835 7.83817 30.1064 7.65644 29.9205 7.55976C28.8524 7.00365 27.7705 6.47372 26.627 5.90162L26.6284 5.9009Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_15_83">
        <rect width="37.006" height="37.006" fill="currentColor" transform="translate(0.441406 0.50293)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
