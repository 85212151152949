import React from 'react';

import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';

import { array } from '@app/lib';
import { Icons } from '@app/components';

type RadioPickerPropTypes = {
  containerClassName?: string;
  options: { label: string; value: string | number; disabled?: boolean; date?: boolean }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  horizontal?: boolean;
};

const RadioPicker: React.FC<RadioPickerPropTypes> = ({ containerClassName, className, options, value, onChange }) => {
  return (
    <RadioGroup className={containerClassName} value={value} onChange={onChange}>
      <div className={cn(className)}>
        {!array.isEmpty(options) &&
          options.map((option) => (
            <RadioGroup.Option
              disabled={option?.disabled}
              key={option.value}
              value={option.value}
              className={({ checked }) =>
                cn('rounded-xl py-4 flex cursor-pointer focus:outline-none transition-colors', {
                  'border-gray-600 border-0.5 px-3 ': !checked && option.disabled === false && !option.date,
                  'border-gray-800 border-0.5 px-3': checked && option.disabled === false && !option.date,
                  'border-gray-400 border-0.5 px-3 ': option.disabled === true && !option.date,
                })
              }
            >
              {({ checked }) => (
                <div className="flex items-center w-full">
                  <div
                    className={cn('flex-shrink-0 mr-2.5 transition-colors', {
                      'text-gray-500': !checked,
                      'text-gray-900': checked,
                      'text-gray-400': option.disabled === true,
                      'text-blue-500': option.date && checked,
                    })}
                  >
                    {checked ? <Icons.RadioCheck className="w-6 h-6" /> : <Icons.EmptySquare className="w-6 h-6" />}
                  </div>

                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={cn('font-semibold text-3.5', {
                          'text-gray-900': option.disabled === false,
                          'text-gray-400': option.disabled === true,
                        })}
                      >
                        {option.label}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
};

export default RadioPicker;
