import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Button, Form as FormComponents, RadioPicker } from '@app/components';
import useLocale from '@app/hooks/useLocale';

type FormPropTypes = {
  onSubmitForm: any;
  isEdit?: boolean;
};

const Form: React.FC<FormPropTypes> = ({ onSubmitForm, isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.invoice.form',
  };
  const { t } = useLocale();

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const invoiceTypeWatch = watch('invoice_type');

  function handlePickerChange(data: any) {
    setValue('invoice_type', data);
  }

  return (
    <form className="rounded-3 flex flex-col items-start bg-white p-5" onSubmit={handleSubmit(onSubmitForm)}>
      <div className="flex flex-col w-full">
        {!isEdit && (
          <>
            <label className="font-bold">{t('invoiceType', SCOPE_OPTIONS)}</label>
            <RadioPicker
              className="flex items-center gap-8"
              options={[
                { label: t('personal', SCOPE_OPTIONS), value: 0 },
                { label: t('business', SCOPE_OPTIONS), value: 1 },
              ]}
              value={invoiceTypeWatch}
              onChange={handlePickerChange}
            />
          </>
        )}
        {invoiceTypeWatch === 1 && (
          <>
            <FormComponents.Input
              type="text"
              id="address"
              label={t('address', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('address', {
                required: { value: true, message: t('errors.address.required', SCOPE_OPTIONS) },
              })}
              error={errors.tax_number?.message}
            />
            <FormComponents.Input
              type="text"
              id="business_name"
              label={t('businessName', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('business_name', {
                required: { value: true, message: t('errors.businessName.required') },
              })}
              error={errors.tax_number?.message}
            />
            <FormComponents.Input
              type="text"
              id="tax_number"
              label={t('taxNumber', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('tax_number', {
                required: { value: true, message: t('errors.taxNumber.required', SCOPE_OPTIONS) },
              })}
              error={errors.tax_number?.message}
            />
            <FormComponents.Input
              type="text"
              id="tax_office"
              label={t('taxOffice', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('tax_office', {
                required: { value: true, message: t('errors.taxOffice.required', SCOPE_OPTIONS) },
              })}
              error={errors.tax_office?.message}
            />
          </>
        )}
        {invoiceTypeWatch === 0 && (
          <>
            <FormComponents.Input
              type="text"
              id="name"
              label={t('name', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('name', { required: { value: true, message: t('errors.name.required', SCOPE_OPTIONS) } })}
              error={errors.name?.message}
            />
            <FormComponents.Input
              type="text"
              id="surname"
              label={t('surname', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('surname', { required: { value: true, message: t('errors.surname.required', SCOPE_OPTIONS) } })}
              error={errors.surname?.message}
            />
            <FormComponents.Input
              type="text"
              id="address"
              label={t('address', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('address', { required: { value: true, message: t('errors.address.required', SCOPE_OPTIONS) } })}
              error={errors.address?.message}
            />
            <FormComponents.Input
              type="text"
              id="id_number"
              label={t('idNumber', SCOPE_OPTIONS)}
              className="mt-4 bg-white rounded-3.5"
              inputClassname="w-full"
              {...register('id_number', {
                required: { value: true, message: t('errors.idNumber.required', SCOPE_OPTIONS) },
              })}
              error={errors.id_number?.message}
            />
          </>
        )}
        <div className="w-full flex justify-center mt-4">
          <Button theme="blue" type="submit" className="px-12" label={t('save', SCOPE_OPTIONS)} block={false} />
        </div>
      </div>
    </form>
  );
};

export default Form;
