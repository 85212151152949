import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';
import type { BranchCanvaImage } from '@app/api';
import { CURRENCY as CURRENCY_MAP } from '@app/constants';
import CanvaReachEstimate from './CanvaReachEstimate';
import CanvaGmSettingsTab from './CanvaGmSettingsTab';
import { branch as branchLib } from '@app/lib';

type SettingsFormPropTypes = {
  canvaImage: BranchCanvaImage;
  // campaign: Partial<CampaignType> & Partial<BranchCampaignType>;
  // campaignStopTime?: string;
  // budget?: number;
  // radius?: number;
  // isEdit?: boolean;
  // cpm: number;
};

const CanvaSettingsForm: React.FC<SettingsFormPropTypes> = ({ canvaImage }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const branchCurrency = branchLib.currency(branch);
  const MIN_BUDGET = branchLib.minimumBudgetBranch(branch);
  const MAX_BUDGET = 500;
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();
  const budgetWatch = watch('budget', MIN_BUDGET);
  const [maxBudget, setMaxBudget] = useState(MAX_BUDGET);

  useEffect(() => {
    register('maxEstimate');
    register('minEstimate');
  }, []);

  return (
    <Box className="w-full">
      <Box className="bg-white shadow-sm px-4">
        <Form.Input
          type="text"
          id="name"
          name="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          defaultValue={canvaImage.name}
          disabled={true}
          requiredSign={true}
        />
      </Box>
      {/* <Box className="bg-white shadow-sm px-4 relative">
        <Campaign.ScopeRange
          control={control}
          cpm={cpm}
          {...register('scopeRange', {
            onChange: (e) => {
              const value = e.target.value;

              setValue('scopeRange', value);
            },
          })}
        />
      </Box> */}
      <Box className="bg-white shadow-sm px-4">
        <div className="flex items-center justify-between mt-5">
          <Form.Range
            label={t('form.budget.label', SCOPE_OPTIONS)}
            id="budget"
            className="w-3/4 border-none"
            defaultValue={MIN_BUDGET}
            control={control}
            minValue={MIN_BUDGET}
            maxValue={maxBudget}
            error={errors.budget}
            {...register('budget', {
              onChange: (e) => {
                const value = e.target.value;

                setValue('budget', value);
              },
              min: {
                value: MIN_BUDGET,
                message: t('form.budget.errors.min', {
                  ...SCOPE_OPTIONS,
                  min: MIN_BUDGET,
                  currency: branchCurrency,
                }),
              },
              required: {
                value: true,
                message: t('form.budget.errors.required', SCOPE_OPTIONS),
              },
            })}
          />
          <div className="relative flex flex-col justify-between w-1/4 px-4 items-start">
            <b>
              <span className="absolute left-8 top-[50%] translate-y-[-50%] text-6">{CURRENCY_MAP[branchCurrency]}</span>
            </b>
            <div className="absolute left-16 top-[50%] translate-y-[-50%] w-[1px] h-[60%] bg-black-800"></div>
            <input
              type="text"
              id="budgetText"
              value={`${budgetWatch ?? MIN_BUDGET}`}
              onFocus={(event) => event.target.select()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Number(event.target.value);

                if (!Number.isNaN(value)) {
                  value <= 2000 && setValue('budget', value, { shouldValidate: true });
                }
                value > MAX_BUDGET && value <= 2000 && setMaxBudget(value);
              }}
              className="rounded text-6 pl-16 pr-2 py-2  font-bold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
          </div>
        </div>
      </Box>

      <div className="flex items-center w-full space-x-4">
        <CanvaReachEstimate />
      </div>

      <CanvaGmSettingsTab />
    </Box>
  );
};

export default CanvaSettingsForm;
