import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
      <path d="M2422 5100 c-40 -10 -97 -32 -125 -47 -40 -21 -294 -268 -1110 -1082 -629 -628 -1074 -1079 -1097 -1113 -60 -90 -84 -174 -85 -293 0 -122 26 -214 84 -301 25 -36 450 -468 1093 -1110 818 -817 1066 -1058 1110 -1083 173 -96 399 -90 565 17 26 17 523 507 1104 1089 819 820 1063 1071 1088 1114 38 70 71 193 71 269 0 76 -33 199 -71 269 -25 43 -269 294 -1088 1114 -581 582 -1078 1072 -1104 1089 -124 80 -294 107 -435 68z m228 -321 c68 -30 2092 -2053 2126 -2124 31 -66 31 -127 -1 -191 -36 -72 -2060 -2094 -2125 -2123 -57 -26 -123 -26 -180 0 -65 29 -2089 2051 -2125 2123 -33 65 -33 127 0 192 36 72 2060 2094 2125 2123 25 11 65 21 90 21 25 0 65 -10 90 -21z" />
      <path d="M3000 3669 c-86 -34 -128 -149 -82 -224 11 -16 83 -96 161 -177 l143 -148 -800 -2 c-791 -3 -801 -3 -828 -24 -15 -11 -37 -33 -48 -48 -21 -27 -21 -40 -24 -591 -3 -628 -5 -609 64 -661 31 -24 47 -29 95 -29 62 0 94 17 133 69 20 27 21 41 24 497 l3 469 681 0 681 0 -24 -27 c-13 -16 -83 -89 -155 -163 -142 -145 -154 -167 -139 -247 9 -49 69 -109 118 -118 90 -17 86 -20 425 322 173 173 318 327 323 340 13 36 11 94 -6 126 -23 44 -576 607 -617 628 -40 21 -88 24 -128 8z" />
    </g>
  </svg>
);

export default SvgComponent;
