import { useMutation } from 'react-query';
import { AxiosInstance, AxiosPromise } from 'axios';

import type { ImageAttachment } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function uploadStorePicture(branchId: string, payload: object, axiosInstance: AxiosInstance): AxiosPromise<ImageAttachment> {
  return axiosInstance
    .post(`/v2/branch_account/branches/${branchId}/attachments/images/store_picture`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useUploadStorePicture(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((values: { payload: object }) => uploadStorePicture(branchId, values.payload, axiosInstance), props);
}
