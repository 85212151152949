/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CampaignTargeting } from './campaign-targeting';
import { CampaignVariables } from './campaign-variables';
import { Template } from './template';

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    lead_generation_allowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    min_campaign_date_range_in_days?: number;
    /**
     * 
     * @type {CampaignTargeting}
     * @memberof Campaign
     */
    targeting: CampaignTargeting;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    permissions?: Array<CampaignPermissionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    allowed_objectives?: Array<CampaignAllowedObjectivesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    allowed_group_ids: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    attended_branches_count?: number;
    /**
     * 
     * @type {Template}
     * @memberof Campaign
     */
    template: Template;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    geo_location_type: CampaignGeoLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    status: CampaignStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    stop_time?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    updated_at: string;
    /**
     * 
     * @type {CampaignVariables}
     * @memberof Campaign
     */
    variables?: CampaignVariables;
}

/**
    * @export
    * @enum {string}
    */
export enum CampaignPermissionsEnum {
    Locationsedit = 'targeting_locations:edit',
    Agesedit = 'targeting_ages:edit',
    Interestsedit = 'targeting_interests:edit',
    Gendersedit = 'targeting_genders:edit',
    Variablesedit = 'targeting_variables:edit'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignAllowedObjectivesEnum {
    WhatsappMessage = 'WHATSAPP_MESSAGE',
    Messenger = 'MESSENGER',
    CallNow = 'CALL_NOW',
    FinalUrl = 'FINAL_URL',
    InstagramVisit = 'INSTAGRAM_VISIT',
    LeadGeneration = 'LEAD_GENERATION',
    AndroidAppInstalls = 'ANDROID_APP_INSTALLS',
    IosAppInstalls = 'IOS_APP_INSTALLS',
    OutcomeAwareness = 'OUTCOME_AWARENESS',
    OutcomeSales = 'OUTCOME_SALES',
    GetDirections = 'GET_DIRECTIONS'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignGeoLocationTypeEnum {
    Free = 'free',
    Range = 'range',
    City = 'city'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignStatusEnum {
    Active = 'active',
    Finished = 'finished',
    Cancelled = 'cancelled'
}



