import React, { Fragment } from 'react';

import cn from 'classnames';

import { array } from '@app/lib';
import { Icons, Integration } from '@app/components';
import SwitchAccount from 'components/Settings/SwitchAccount';

type BreadcrumbPropTypes = {
  className?: string;
  items: string[];
};

const Breadcrumb: React.FC<BreadcrumbPropTypes> = ({ className, items = [] }) => {
  const lastItem = array.last(items);

  if (array.isEmpty(items)) {
    return null;
  }

  return (
    <div className={cn('bg-gray-200 items-center justify-between', className)}>
      <Integration.Banner />
      <div className={cn('pl-6 h-18 flex flex-row items-center justify-between capitalize')}>
        <div className="flex flex-row items-center text-sm  space-x-2 ">
          {items.map((item, index) => (
            <Fragment key={index}>
              <div className=" flex items-center">
                <span className={cn('font-semibold text-gray-500 select-none', { 'text-black-800': item === lastItem })}>{item}</span>
              </div>
              {!array.isLastItem(items, item) && (
                <span className="text-gray-500">
                  <Icons.ChevronRight className="w-3 h-3" />
                </span>
              )}
            </Fragment>
          ))}
        </div>
        <SwitchAccount />
      </div>
    </div>
  );
};

export default Breadcrumb;
