/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchSubscriptionInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { SubscriptionListResponse } from '../model';
// @ts-ignore
import { SubscriptionSuccessResponse } from '../model';
/**
 * SubscriptionsBranchV2Api - axios parameter creator
 * @export
 */
export const SubscriptionsBranchV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get branch subscriptions
         * @summary Get branch subscriptions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchSubscriptions: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchSubscriptions', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/subscriptions`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update branch subscription
         * @summary Update branch subscription
         * @param {string} branchId The id of the branch
         * @param {string} branchSubscriptionId The id of the branch subscription
         * @param {BranchSubscriptionInput} branchSubscriptionInput Branch subscription request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchSubscription: async (branchId: string, branchSubscriptionId: string, branchSubscriptionInput: BranchSubscriptionInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateBranchSubscription', 'branchId', branchId)
            // verify required parameter 'branchSubscriptionId' is not null or undefined
            assertParamExists('updateBranchSubscription', 'branchSubscriptionId', branchSubscriptionId)
            // verify required parameter 'branchSubscriptionInput' is not null or undefined
            assertParamExists('updateBranchSubscription', 'branchSubscriptionInput', branchSubscriptionInput)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/subscriptions/{branch_subscription_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"branch_subscription_id"}}`, encodeURIComponent(String(branchSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchSubscriptionInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsBranchV2Api - functional programming interface
 * @export
 */
export const SubscriptionsBranchV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsBranchV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * Get branch subscriptions
         * @summary Get branch subscriptions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchSubscriptions(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchSubscriptions(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update branch subscription
         * @summary Update branch subscription
         * @param {string} branchId The id of the branch
         * @param {string} branchSubscriptionId The id of the branch subscription
         * @param {BranchSubscriptionInput} branchSubscriptionInput Branch subscription request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranchSubscription(branchId: string, branchSubscriptionId: string, branchSubscriptionInput: BranchSubscriptionInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranchSubscription(branchId, branchSubscriptionId, branchSubscriptionInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsBranchV2Api - factory interface
 * @export
 */
export const SubscriptionsBranchV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsBranchV2ApiFp(configuration)
    return {
        /**
         * Get branch subscriptions
         * @summary Get branch subscriptions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchSubscriptions(branchId: string, options?: any): AxiosPromise<SubscriptionListResponse> {
            return localVarFp.getBranchSubscriptions(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update branch subscription
         * @summary Update branch subscription
         * @param {string} branchId The id of the branch
         * @param {string} branchSubscriptionId The id of the branch subscription
         * @param {BranchSubscriptionInput} branchSubscriptionInput Branch subscription request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchSubscription(branchId: string, branchSubscriptionId: string, branchSubscriptionInput: BranchSubscriptionInput, options?: any): AxiosPromise<SubscriptionSuccessResponse> {
            return localVarFp.updateBranchSubscription(branchId, branchSubscriptionId, branchSubscriptionInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsBranchV2Api - interface
 * @export
 * @interface SubscriptionsBranchV2Api
 */
export interface SubscriptionsBranchV2ApiInterface {
    /**
     * Get branch subscriptions
     * @summary Get branch subscriptions
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsBranchV2ApiInterface
     */
    getBranchSubscriptions(branchId: string, options?: any): AxiosPromise<SubscriptionListResponse>;

    /**
     * Update branch subscription
     * @summary Update branch subscription
     * @param {string} branchId The id of the branch
     * @param {string} branchSubscriptionId The id of the branch subscription
     * @param {BranchSubscriptionInput} branchSubscriptionInput Branch subscription request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsBranchV2ApiInterface
     */
    updateBranchSubscription(branchId: string, branchSubscriptionId: string, branchSubscriptionInput: BranchSubscriptionInput, options?: any): AxiosPromise<SubscriptionSuccessResponse>;

}

/**
 * SubscriptionsBranchV2Api - object-oriented interface
 * @export
 * @class SubscriptionsBranchV2Api
 * @extends {BaseAPI}
 */
export class SubscriptionsBranchV2Api extends BaseAPI implements SubscriptionsBranchV2ApiInterface {
    /**
     * Get branch subscriptions
     * @summary Get branch subscriptions
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsBranchV2Api
     */
    public getBranchSubscriptions(branchId: string, options?: any) {
        return SubscriptionsBranchV2ApiFp(this.configuration).getBranchSubscriptions(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update branch subscription
     * @summary Update branch subscription
     * @param {string} branchId The id of the branch
     * @param {string} branchSubscriptionId The id of the branch subscription
     * @param {BranchSubscriptionInput} branchSubscriptionInput Branch subscription request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsBranchV2Api
     */
    public updateBranchSubscription(branchId: string, branchSubscriptionId: string, branchSubscriptionInput: BranchSubscriptionInput, options?: any) {
        return SubscriptionsBranchV2ApiFp(this.configuration).updateBranchSubscription(branchId, branchSubscriptionId, branchSubscriptionInput, options).then((request) => request(this.axios, this.basePath));
    }
}
