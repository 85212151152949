import React from 'react';

import { useLocale } from '@app/hooks';
import { Icons } from '@app/components';
import { AD_PREVIEWS } from '@app/constants';

type InstagramFeedPropTypes = {
  companyName?: string;
  message: string;
  image: string;
  profilePicture?: string;
  targetingType?: string;
  attachmentType?: string;
};

const InstagramFeed: React.FC<InstagramFeedPropTypes> = ({
  companyName,
  image,
  message,
  targetingType,
  profilePicture,
  attachmentType,
}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreview.InstagramFeed',
  };
  const { t } = useLocale();

  return (
    <div className="bg-white select-none border-b border-t border-gray-300 " style={{ width: 320, height: 567 }}>
      <div className="flex flex-row  bg-slate-50 justify-center items-center border-b border-gray-300">
        <Icons.IntstagramText className="w-80 h-9 py-2" />
      </div>

      <div className="flex flex-row h-13 px-2">
        <div className="w-9 flex rounded-full justify-center self-center aspect-square">
          <img src={profilePicture} alt="profile" className="object-cover rounded-full" />
        </div>

        <span className="flex flex-row justify-between w-full">
          <div className="flex flex-col ml-2 align-center self-center">
            <span className="font-bold text-3 text-gray-900">{companyName || AD_PREVIEWS.COMPANY_NAME}</span>
            <span className="leading-none text-gray-500 text-2.5">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex self-center">
            <Icons.TreeDot className="w-4 h-4" />
          </div>
        </span>
      </div>

      <div className="flex justify-center aspect-square aspect-1">
        {attachmentType === 'video' ? (
          <>
            <video
              src={image}
              className="my-3 rounded aspect-1 object-contain bg-black"
              onMouseEnter={(e) => e.currentTarget.play()}
              onMouseLeave={(e) => e.currentTarget.pause()}
              muted
            >
              <track kind="captions" />
            </video>
          </>
        ) : (
          <img src={image} alt="feed_image" className="object-cover" width="100%" height="100%" />
        )}
      </div>

      {targetingType && (
        <div className="px-2 py-2 my-1 flex flex-row justify-between w-full">
          <div className="flex flex-col">
            <span className="leading-none text-black-700 text-3 font-medium">{t(`callToAction.${targetingType}`)}</span>
          </div>
          <div className="flex flex-col">
            <Icons.ChevronRight className="w-3 h-3" />
          </div>
        </div>
      )}

      <hr className="border-gray-300 mx-2" />

      <div className="px-2 pb-2 mt-2 flex flex-row justify-between w-full">
        <div className="flex flex-row">
          <div className="mr-3">
            <span className="leading-none text-gray-700 text-3.5 font-medium ">
              <Icons.InstagramLike className="w-5 h-5" />
            </span>
          </div>
          <div className="mr-3">
            <span className="leading-none text-gray-700 text-3.5 ">
              <Icons.InstagramComment className="w-5 h-5" />
            </span>
          </div>
          <div>
            <span className="leading-none text-gray-700 text-3.5">
              <Icons.InstagramShare className="w-5 h-5" />
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <Icons.InstagramSave className="w-5 h-5" />
        </div>
      </div>

      <div className="flex flex-row px-2">
        <span className="text-zinc-500 text-3 line-clamp-2">
          <span className="text-zinc-900">{companyName}</span> {message}
        </span>
      </div>
    </div>
  );
};

export default InstagramFeed;
