import Checkbox from './Checkbox';
import Date from './Date';
import Input from './Input';
import PhoneInput from './PhoneInput';
import Range from './Range';
import Select from './Select';
import CreatableSelect from './CreatableSelect';
import Selectbox from './Selectbox';
import Textarea from './Textarea';
import ImageUpload from './ImageUpload';

export default {
  Checkbox,
  Date,
  Input,
  PhoneInput,
  Range,
  Select,
  Selectbox,
  Textarea,
  CreatableSelect,
  ImageUpload,
};
