import { useEffect, useState } from 'react';

import cn from 'classnames';

import type { TemplateAttachments } from '@app/api';
import { BRANCH_KEYS, CALL_TO_ACTION, POSITIONS, PUBLISHER_PLATFORMS } from '@app/constants';
import { array, preview } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';

import FacebookFeed from './FacebookFeed';
import FacebookStory from './FacebookStory';
import InstagramFeed from './InstagramFeed';
import InstagramStory from './InstagramStory';
import PreviewHeader from './PreviewHeader';
import DownloadImages from './DownloadImages';

type AdsPreviewPropTypes = {
  campaign: {
    message: string;
    headline: string;
    description: string;
    feedImage?: string;
    storyImage?: string;
    targetingType: string;
    callToAction: string;
  };
  attachments: Array<TemplateAttachments>;
  accountInfo?: {
    instagramUsername: string;
    instagramProfile: string;
    facebookUsername: string;
    facebookName: string;
    facebookProfile: string;
  };
  customImages?: {
    feed: string;
    story: string;
  };
  loading?: boolean;
  attachmentType: string;
};

const AdsPreview: React.FC<AdsPreviewPropTypes> = ({ loading, campaign, accountInfo, attachments, customImages, attachmentType }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreview.index',
  };
  const { t } = useLocale();
  const [selectedPlatform, setSelectedPlatform] = useState<string>(PUBLISHER_PLATFORMS.FACEBOOK);
  const [selectedPlacementType, setSelectedPlacementType] = useState<string>(POSITIONS.FEED);
  const [canvaEnabledTypes, setCanvaEnabledTypes] = useState<string[]>([POSITIONS.FEED, POSITIONS.STORY]);
  const isFacebookFeed = selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK && selectedPlacementType === POSITIONS.FEED;
  const isFacebookStory = selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK && selectedPlacementType === POSITIONS.STORY;
  const isInstagramFeed = selectedPlatform === PUBLISHER_PLATFORMS.INSTAGRAM && selectedPlacementType === POSITIONS.FEED;
  const isInstagramStory = selectedPlatform === PUBLISHER_PLATFORMS.INSTAGRAM && selectedPlacementType === POSITIONS.STORY;
  const isPlatformDisabled = campaign.targetingType === CALL_TO_ACTION.INSTAGRAM_VISIT;
  const isFacebookStoryDisabled = campaign.targetingType === CALL_TO_ACTION.CALL_NOW && selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK;
  const { branch } = useAuth();

  function handleVariableText(text?: string, branch?: any) {
    if (!text) {
      return '';
    }

    if (!branch) {
      return text;
    }

    return text
      .replaceAll(BRANCH_KEYS.NAME, branch.name)
      .replaceAll(BRANCH_KEYS.ADDRESS, branch.address)
      .replaceAll(BRANCH_KEYS.ADDRESS_DIRECTION, branch.address_direction)
      .replaceAll(BRANCH_KEYS.WEBSITE, branch.website)
      .replaceAll(BRANCH_KEYS.PHONE, branch.phone_number)
      .replaceAll(BRANCH_KEYS.VAR_PRICE, branch.var_price || '')
      .replaceAll(BRANCH_KEYS.CITY, branch.city)
      .replaceAll(BRANCH_KEYS.DISTRICT, branch.district);
  }

  useEffect(() => {
    if (isPlatformDisabled) {
      setSelectedPlatform(PUBLISHER_PLATFORMS.INSTAGRAM);
    }
  }, [isPlatformDisabled]);

  useEffect(() => {
    if (isFacebookStoryDisabled || (attachments.length === 0 && !customImages?.story)) {
      setSelectedPlacementType(POSITIONS.FEED);
    }
  }, [isFacebookStoryDisabled]);

  useEffect(() => {
    if (attachments?.length === 0 && !customImages?.feed) {
      setSelectedPlacementType(POSITIONS.STORY);
      setCanvaEnabledTypes([POSITIONS.STORY]);
    }

    if (attachments?.length === 0 && !customImages?.story) {
      setSelectedPlacementType(POSITIONS.FEED);
      setCanvaEnabledTypes([POSITIONS.FEED]);
    }

    if (attachments?.length === 1) {
      if (attachments[0].placement_type === POSITIONS.FEED.toUpperCase()) {
        setSelectedPlacementType(POSITIONS.FEED);
        setCanvaEnabledTypes([POSITIONS.FEED]);
      }

      if (attachments[0].placement_type === POSITIONS.STORY.toUpperCase()) {
        setSelectedPlacementType(POSITIONS.STORY);
        setCanvaEnabledTypes([POSITIONS.STORY]);
      }
    }
  }, []);

  return (
    <div
      className={cn('flex flex-col items-center w-full origin-top laptop:scale-60 desktop:scale-80 xl:scale-100', {
        'opacity-40': loading,
        'pointer-events-none': loading,
        'cursor-not-allowed': loading,
      })}
    >
      <div className="flex flex-col bg-white mt-5 p-4.5 rounded-2 shadow-sm w-full min-w-80">
        <div className="flex flex-col items-center">
          <PreviewHeader
            placement={selectedPlacementType}
            platform={selectedPlatform}
            onPlatformChange={setSelectedPlatform}
            isPlatformDisabled={isPlatformDisabled}
          />
          <>
            {isFacebookFeed && (attachments || customImages?.feed) && (
              <FacebookFeed
                companyName={accountInfo?.facebookName}
                profilePicture={accountInfo?.facebookProfile}
                image={customImages?.feed || (attachments?.find((item) => item.placement_type === 'FEED')?.file?.url as string)}
                message={handleVariableText(campaign.message, branch)}
                headline={handleVariableText(campaign.headline, branch)}
                description={handleVariableText(campaign.description, branch)}
                action={campaign.callToAction}
                targetingType={preview.actionByTargetingType(campaign.targetingType)}
                attachmentType={attachmentType}
              />
            )}

            {isInstagramFeed && (attachments || customImages?.feed) && (
              <InstagramFeed
                companyName={accountInfo?.instagramUsername}
                profilePicture={accountInfo?.instagramProfile}
                image={customImages?.feed || (attachments?.find((item) => item.placement_type === 'FEED')?.file?.url as string)}
                message={handleVariableText(campaign.message, branch)}
                targetingType={preview.actionByTargetingType(campaign.targetingType)}
                attachmentType={attachmentType}
              />
            )}

            {isInstagramStory && (attachments || customImages?.story) && (
              <InstagramStory
                companyName={accountInfo?.instagramUsername}
                profilePicture={accountInfo?.instagramProfile}
                image={customImages?.story || (attachments?.find((item) => item.placement_type === 'STORY')?.file?.url as string)}
                targetingType={preview.actionByTargetingType(campaign.targetingType)}
                attachmentType={attachmentType}
              />
            )}

            {isFacebookStory && (attachments || customImages?.story) && (
              <FacebookStory
                companyName={accountInfo?.facebookName}
                profilePicture={accountInfo?.facebookProfile}
                image={customImages?.story || (attachments?.find((item) => item.placement_type === 'STORY')?.file?.url as string)}
                targetingType={preview.actionByTargetingType(campaign.targetingType)}
                attachmentType={attachmentType}
              />
            )}
          </>

          <div className="flex items-center text-gray-500 mt-4 justify-center w-full">
            <button
              className={cn('px-4 py-1.5 border-y border-l border-gray-500 rounded-tl-md rounded-bl-md w-1/2', {
                'bg-black-800 text-white': selectedPlacementType === POSITIONS.FEED,
              })}
              onClick={() => setSelectedPlacementType(POSITIONS.FEED)}
              disabled={!!customImages?.feed || !canvaEnabledTypes?.includes(POSITIONS.FEED)}
            >
              {t('actions.feed', SCOPE_OPTIONS)}
            </button>
            <button
              className={cn('px-4 py-1.5 border-y border-r border-gray-500 rounded-tr-md rounded-br-md w-1/2', {
                'bg-black-800 text-white': selectedPlacementType === POSITIONS.STORY,
              })}
              onClick={() => setSelectedPlacementType(POSITIONS.STORY)}
              disabled={isFacebookStoryDisabled || !!customImages?.story || !canvaEnabledTypes?.includes(POSITIONS.STORY)}
            >
              {t('actions.story', SCOPE_OPTIONS)}
            </button>
          </div>
          {attachments && !array.isEmpty(attachments) && <DownloadImages attachments={attachments} />}
        </div>
      </div>
    </div>
  );
};

export default AdsPreview;
