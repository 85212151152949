import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';

type DropdownPropTypes = {
  id?: string;
  button: JSX.Element | string;
  items: { value: string; label: JSX.Element | string }[];
  onButtonClick: (type: string) => void;
};

const Dropdown: React.FC<DropdownPropTypes> = ({ id, button, items, onButtonClick }) => {
  return (
    <Menu id={id} as="div" className="relative inline-block text-left">
      <Menu.Button>{button}</Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right drop-shadow-box absolute right-0 rounded-xl overflow-hidden bg-white mt-1 z-4">
          <div className="py-1">
            {items &&
              items.map((item) => (
                <Menu.Item key={item.value}>
                  {() => (
                    <button
                      id={`${id}-option-${item.label}`}
                      type="button"
                      onClick={() => onButtonClick(item.value)}
                      className="block w-full py-2 px-4 text-gray-500 font-semibold text-sm hover:bg-gray-500 hover:bg-opacity-10"
                    >
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
