import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormBranchAccountApi, InvoiceInformationsBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listInvoices(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new InvoiceInformationsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listBranchInvoiceInformation(branchId).then((res) => res.data);
}

export default function useListInvoices(): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useListInvoiceInformations', branchId], () => listInvoices(branchId, axiosInstance));
}
