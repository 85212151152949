import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { InvoiceInformationsBranchV2Api } from '@app/api';
import type { InvoiceInformationCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateInvoice(
  branchId: string,
  invoiceId: string,
  invoiceCreateInput: InvoiceInformationCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new InvoiceInformationsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateBranchInvoiceInformation(branchId, invoiceId, invoiceCreateInput).then((res) => res.data);
}

export default function useUpdateInvoice(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; invoiceId: string; invoiceCreateInput: InvoiceInformationCreateInput }) =>
      updateInvoice(values.branchId, values.invoiceId, values.invoiceCreateInput, axiosInstance),
    props
  );
}
