/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCompany } from './branch-company';
import { BranchDefaultCustomLocation } from './branch-default-custom-location';
import { BranchDefaultFacebookCity } from './branch-default-facebook-city';
import { BranchFacebookIntegration } from './branch-facebook-integration';
import { BranchGoogleIntegration } from './branch-google-integration';
import { BranchLegalInformation } from './branch-legal-information';
import { BranchLocation } from './branch-location';
import { BranchOwner } from './branch-owner';
import { BranchSummary } from './branch-summary';

/**
 * 
 * @export
 * @interface Branch
 */
export interface Branch {
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    has_pending_invitations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    has_active_campaigns?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    address_direction: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    phone_number: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    whatsapp_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    profile_picture?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    store_picture?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    is_location?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    location_type?: BranchLocationTypeEnum;
    /**
     * 
     * @type {BranchDefaultFacebookCity}
     * @memberof Branch
     */
    default_facebook_city?: BranchDefaultFacebookCity;
    /**
     * 
     * @type {BranchDefaultFacebookCity}
     * @memberof Branch
     */
    default_google_city?: BranchDefaultFacebookCity;
    /**
     * 
     * @type {BranchDefaultCustomLocation}
     * @memberof Branch
     */
    default_custom_location?: BranchDefaultCustomLocation;
    /**
     * 
     * @type {BranchLocation}
     * @memberof Branch
     */
    location: BranchLocation;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    is_onboarded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    force_open_facebook_login_modal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    updated_at: string;
    /**
     * 
     * @type {BranchOwner}
     * @memberof Branch
     */
    owner?: BranchOwner;
    /**
     * 
     * @type {BranchFacebookIntegration}
     * @memberof Branch
     */
    facebook_integration?: BranchFacebookIntegration;
    /**
     * 
     * @type {BranchGoogleIntegration}
     * @memberof Branch
     */
    google_integration?: BranchGoogleIntegration;
    /**
     * 
     * @type {BranchCompany}
     * @memberof Branch
     */
    company?: BranchCompany;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    allow_gm_update_campaigns?: boolean;
    /**
     * 
     * @type {BranchLegalInformation}
     * @memberof Branch
     */
    legal_information?: BranchLegalInformation;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    branch_code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    whatsapp_support?: boolean;
    /**
     * 
     * @type {BranchSummary}
     * @memberof Branch
     */
    summary: BranchSummary;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchLocationTypeEnum {
    City = 'CITY',
    Location = 'LOCATION'
}



