/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GenerateTextErrorResponse } from '../model';
// @ts-ignore
import { GenerateTextInput } from '../model';
// @ts-ignore
import { GenerateTextSuccessResponse } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * CanvaGenerateTextBranchApi - axios parameter creator
 * @export
 */
export const CanvaGenerateTextBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate text from ai
         * @summary Generate text from ai
         * @param {string} branchId The id of the branch
         * @param {string} id The id of the canva template
         * @param {GenerateTextInput} generateTextInput Generate text from ai
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCanvaTemplateText: async (branchId: string, id: string, generateTextInput: GenerateTextInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('generateCanvaTemplateText', 'branchId', branchId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateCanvaTemplateText', 'id', id)
            // verify required parameter 'generateTextInput' is not null or undefined
            assertParamExists('generateCanvaTemplateText', 'generateTextInput', generateTextInput)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/mobile/canva_templates/{id}/generate_text`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTextInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanvaGenerateTextBranchApi - functional programming interface
 * @export
 */
export const CanvaGenerateTextBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanvaGenerateTextBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate text from ai
         * @summary Generate text from ai
         * @param {string} branchId The id of the branch
         * @param {string} id The id of the canva template
         * @param {GenerateTextInput} generateTextInput Generate text from ai
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCanvaTemplateText(branchId: string, id: string, generateTextInput: GenerateTextInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCanvaTemplateText(branchId, id, generateTextInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanvaGenerateTextBranchApi - factory interface
 * @export
 */
export const CanvaGenerateTextBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanvaGenerateTextBranchApiFp(configuration)
    return {
        /**
         * Generate text from ai
         * @summary Generate text from ai
         * @param {string} branchId The id of the branch
         * @param {string} id The id of the canva template
         * @param {GenerateTextInput} generateTextInput Generate text from ai
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCanvaTemplateText(branchId: string, id: string, generateTextInput: GenerateTextInput, options?: any): AxiosPromise<GenerateTextSuccessResponse> {
            return localVarFp.generateCanvaTemplateText(branchId, id, generateTextInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanvaGenerateTextBranchApi - interface
 * @export
 * @interface CanvaGenerateTextBranchApi
 */
export interface CanvaGenerateTextBranchApiInterface {
    /**
     * Generate text from ai
     * @summary Generate text from ai
     * @param {string} branchId The id of the branch
     * @param {string} id The id of the canva template
     * @param {GenerateTextInput} generateTextInput Generate text from ai
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaGenerateTextBranchApiInterface
     */
    generateCanvaTemplateText(branchId: string, id: string, generateTextInput: GenerateTextInput, options?: any): AxiosPromise<GenerateTextSuccessResponse>;

}

/**
 * CanvaGenerateTextBranchApi - object-oriented interface
 * @export
 * @class CanvaGenerateTextBranchApi
 * @extends {BaseAPI}
 */
export class CanvaGenerateTextBranchApi extends BaseAPI implements CanvaGenerateTextBranchApiInterface {
    /**
     * Generate text from ai
     * @summary Generate text from ai
     * @param {string} branchId The id of the branch
     * @param {string} id The id of the canva template
     * @param {GenerateTextInput} generateTextInput Generate text from ai
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaGenerateTextBranchApi
     */
    public generateCanvaTemplateText(branchId: string, id: string, generateTextInput: GenerateTextInput, options?: any) {
        return CanvaGenerateTextBranchApiFp(this.configuration).generateCanvaTemplateText(branchId, id, generateTextInput, options).then((request) => request(this.axios, this.basePath));
    }
}
