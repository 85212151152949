import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { InvoiceInformationsBranchV2Api, SubscriptionsBranchV2Api, WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getInvoiceInformation(branchId: string, invoiceId: string, axiosInstance: AxiosInstance) {
  const api = new InvoiceInformationsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getBranchInvoiceInformation(branchId, invoiceId).then((res) => res.data);
}

export default function useGetInvoiceInformation(invoiceId: string) {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(['invoiceInformation', branchId, invoiceId], () => getInvoiceInformation(branchId, invoiceId, axiosInstance));
}
