import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { api } from '@app/hooks';
import { Icons } from '@app/components';
import { array } from '@app/lib';

type ImageUploadPropTypes = {
  name: string;
  label: string;
  ratio: string;
  className?: string;
};

export const ImageUpload: React.FC<ImageUploadPropTypes> = ({ name, label, className }) => {
  const { control, setValue, watch } = useFormContext();

  const imageWatch = watch(name);

  const { mutateAsync: mutateProfilePicture, isLoading: isUploadProfilePictureLoading } = api.useUploadProfilePicture({
    onSuccess: (data: any) => {
      setValue(name, data?.attachment_image?.file?.url);
    },
  });

  const { mutateAsync: mutateStorePicture, isLoading: isUploadStorePictureLoading } = api.useUploadStorePicture({
    onSuccess: (data: any) => {
      setValue(name, data?.attachment_image?.file?.url);
    },
  });

  const onDrop = useCallback(async (file) => {
    const uploadedFile = array.first(file);

    const formData = new FormData();

    if (file) {
      formData.append('file', uploadedFile);
    }

    if (name === 'profilePicture') {
      mutateProfilePicture({ payload: formData });
    }

    if (name === 'storePicture') {
      mutateStorePicture({ payload: formData });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const handleDestroyImage = () => {
    setValue(name, '');
  };

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex flex-col justify-center mt-3 content-center">
        <div className="flex justify-between mb-1.5">
          <label htmlFor={label} className="text-3.5 font-semibold text-gray-900">
            {label}
          </label>
        </div>
        {!imageWatch && (
          <div className={cn('flex items-center py-2.5 font-normal text-sm text-black-800 placeholder-gray-500 outline-none w-full')}>
            <div
              className="w-full p-4 bg-white border-0.5 border-[#bebebe] rounded-3 flex items-center justify-center"
              {...getRootProps()}
              style={{
                pointerEvents: isUploadProfilePictureLoading || isUploadStorePictureLoading ? 'none' : 'auto',
                opacity: isUploadProfilePictureLoading || isUploadStorePictureLoading ? 0.5 : 1,
              }}
            >
              <Controller
                control={control}
                name={name}
                render={() => <input {...getInputProps()} disabled={isUploadProfilePictureLoading || isUploadStorePictureLoading} />}
              />
              <div className="flex flex-col items-center justify-center">
                <Icons.ImageUpload className="h-25" />
                <div className="w-60 !text-center">
                  <span className="text-3 text-gray-900 w-full">{label}</span>
                </div>
                {false && (
                  <div className="flex items-center px-2 mt-2">
                    <span className="inline text-red-400 text-3 text-center">{false}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {imageWatch && (
          <div className="relative w-full h-40 bg-black flex justify-center items-center overflow-hidden">
            <button type="button" onClick={handleDestroyImage} className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2">
              x
            </button>
            <img src={imageWatch} alt="post_image" className="h-full w-full object-contain rounded-3" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
