import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAuth, useLocale } from '@app/hooks';
import { Box, Form, Location } from '@app/components';
import { GEO_LOCATION_TYPE } from '@app/constants';
import CityInput from './CityInput';

const FB_LOCATION_MIN = 1;
const FB_LOCATION_MAX = 80;

const LocationForm: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.LocationForm',
  };
  const { t } = useLocale();
  const {
    unregister,
    formState: { errors },
    watch,
    control,
    setValue,
    register,
  } = useFormContext();
  const geoLocationTypeWatch = watch('geoLocationType');
  const radiusWatch = watch('radius');
  const defaultRange = '';
  const { branch } = useAuth();
  const cityDefaultValue = getDefaultCity();

  function getDefaultCity() {
    return branch?.default_facebook_city ? [branch?.default_facebook_city] : [];
  }

  function getLocation() {
    if (!branch.default_custom_location?.latitude || !branch.default_custom_location?.longitude) {
      return;
    }

    return {
      lat: branch.default_custom_location?.latitude,
      lng: branch.default_custom_location?.longitude,
    };
  }

  const LOCATION_VALUES = [
    {
      label: t('form.geoLocationType.options.range', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.RANGE,
    },
    {
      label: t('form.geoLocationType.options.city', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.CITY,
    },
  ];

  useEffect(() => {
    if (geoLocationTypeWatch === GEO_LOCATION_TYPE.CITY) {
      unregister('radius');
    }

    if (geoLocationTypeWatch === GEO_LOCATION_TYPE.RANGE) {
      unregister('cities');
    }
  }, [geoLocationTypeWatch]);

  useEffect(() => {
    if (defaultRange && !radiusWatch) {
      setValue('radius', defaultRange);
    }
  }, [defaultRange]);

  return (
    <Box className="bg-white px-5 py-4 mt-5">
      <Form.Select
        name="geoLocationType"
        label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
        rules={{
          required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
        }}
        className="mb-4"
        control={control}
        options={LOCATION_VALUES}
        error={errors.geoLocationType}
      />
      {geoLocationTypeWatch === GEO_LOCATION_TYPE.RANGE && (
        <>
          <Form.Range
            label={t('form.radius.label', SCOPE_OPTIONS)}
            id="radius"
            control={control}
            minValue={FB_LOCATION_MIN}
            maxValue={FB_LOCATION_MAX}
            error={errors.radius}
            {...register('radius', {
              onChange: (e) => {
                const value = e.target.value;

                setValue('radius', value);
              },
            })}
          />
          <Location.LoadScript>
            <Location.Circle radius={radiusWatch} location={getLocation()} />
          </Location.LoadScript>
        </>
      )}
      {geoLocationTypeWatch === GEO_LOCATION_TYPE.CITY && <CityInput defaultValue={cityDefaultValue} isGooglePlatform={false} />}
    </Box>
  );
};

export default LocationForm;
