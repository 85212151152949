import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import type { Branch as BranchType } from '@app/api';
import { api, useAuth, useLocale } from '@app/hooks';
import Button from 'components/Button';

const SwitchAccount = () => {
  const { branch, storeBranch } = useAuth();
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.SwitchAccount',
  };
  const { t } = useLocale();
  const router = useRouter();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(branch?.id || null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data } = api.useListBranchUserBranches({
    enabled: typeof window !== 'undefined',
  });

  const redirectToDashboard = () => {
    setTimeout(() => {
      window.location.href = router.query.integration === 'true' ? '/settings/integration' : '/dashboard';
    }, 1000);
  };

  const handleSelectBranch = () => {
    const selectedBranch = data?.branches.find((branch: BranchType) => branch.id === selected);
    if (selectedBranch) {
      storeBranch(selectedBranch, false);
      redirectToDashboard();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (!Array.isArray(data?.branches) || data.branches.length === 1) return null;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        className={`w-12 h-12 flex items-center justify-center rounded-xl cursor-pointer mr-3 
        transition-colors duration-500 ease-in-out transform
        ${isHovered || isOpen ? 'bg-[#2463EB]' : 'bg-[#2463EB1A] scale-100'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={`/images/${isHovered || isOpen ? 'switchAccountWhite' : 'switchAccountBlue'}.png`} alt="User Icon" className="w-6 h-6" />
      </button>

      {isOpen && (
        <div className="absolute top-14 right-2 bg-white shadow-xl rounded-2xl px-5 pt-5 pb-3 w-74 border border-gray-300">
          <h2 className="text-gray-900 text-lg font-extrabold text-center mb-1">{t('title', SCOPE_OPTIONS)}</h2>
          <p className="text-[#6C798B] text-xs text-center mb-4" style={{ textTransform: 'none' }}>
            {t('content', SCOPE_OPTIONS)}
          </p>

          <div className="max-h-60 overflow-y-auto space-y-1">
            {data.branches.map((branch: BranchType, index: number) => (
              <label key={branch.id} className="flex items-center p-1 rounded-xl cursor-pointer transition">
                <input
                  type="radio"
                  name="dealer"
                  value={branch.id}
                  checked={selected === branch.id}
                  onChange={() => setSelected(branch.id)}
                  className="hidden"
                />
                <span
                  className={`w-5 h-5 flex items-center justify-center rounded-full mr-3 transition border ${
                    selected === branch.id ? 'border-[7px] border-[#2463EB]' : 'border-[2px] border-gray-200'
                  }`}
                ></span>
                <div className={`flex items-center w-full p-3 rounded-xl ${index % 2 === 0 ? 'bg-[#2463EB0D]' : ''}`}>
                  <img src="/images/branch-icon.png" alt="Store" className="w-7 h-7 mr-4" />
                  <span className="text-[#181A27] text-sm font-medium">{branch.name}</span>
                </div>
              </label>
            ))}
          </div>

          <div className="flex justify-center mt-1">
            <Button
              disabled={selected === branch.id}
              label={t('button', SCOPE_OPTIONS)}
              theme={selected === branch.id ? 'gray' : 'blue'}
              className="w-62 mt-2 font-normal text-base"
              onClick={handleSelectBranch}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SwitchAccount;
