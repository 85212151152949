import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import type { Campaign as CampaignType, BranchCampaign as BranchCampaignType } from '@app/api';
import { date as dateLib, google as googleLib } from '@app/lib';

import StartDate from './StartDate';
import EndDate from './EndDate';
import Schedule from './Schedule';

type DateComponentPropTypes = {
  campaign?: Partial<CampaignType> & Partial<BranchCampaignType>;
  campaignStopTime?: string;
  isEdit?: boolean;
};

const DateComponent: React.FC<DateComponentPropTypes> = ({ campaign, isEdit, campaignStopTime }) => {
  const { watch, setValue } = useFormContext();
  const startTimeWatch = watch('startTime');
  const stopTimeWatch = watch('stopTime');
  const hasStartTime = dateLib.isValid(startTimeWatch);
  const hasStopTime = dateLib.isValid(stopTimeWatch);
  const isGooglePlatform = googleLib.isPlatformGoogle(campaign?.template);

  useEffect(() => {
    if (!watch('startTime') && campaign?.start_time) {
      setValue('startTime', campaign.start_time);
    }
  }, [watch, setValue, campaign?.start_time]);

  return (
    <div className="grid gap-4 mt-5 grid-cols-2">
      {hasStartTime && <StartDate startTime={campaign?.start_time || startTimeWatch} isEdit={isEdit} isGooglePlatform={isGooglePlatform} />}
      <EndDate
        stopTime={campaign?.stop_time}
        startTime={campaign?.start_time || startTimeWatch}
        campaignStopTime={campaignStopTime}
        isEdit={isEdit}
        minDateRange={campaign?.min_campaign_date_range_in_days}
        isGooglePlatform={isGooglePlatform}
      />
      {!isGooglePlatform && hasStartTime && hasStopTime && (
        <div className=" col-span-2">
          <Schedule startTime={startTimeWatch} stopTime={stopTimeWatch} />
        </div>
      )}
    </div>
  );
};

export default DateComponent;
