/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { V2WalletTransactionsSuccessResponseCampaign } from './v2-wallet-transactions-success-response-campaign';
import { V2WalletTransactionsSuccessResponseOrder } from './v2-wallet-transactions-success-response-order';
import { V2WalletTransactionsSuccessResponseTransferDetails } from './v2-wallet-transactions-success-response-transfer-details';
import { V2WalletTransactionsSuccessResponseUser } from './v2-wallet-transactions-success-response-user';

/**
 * 
 * @export
 * @interface V2WalletTransactionsSuccessResponseTransactions
 */
export interface V2WalletTransactionsSuccessResponseTransactions {
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    ip_address: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    transaction_type: V2WalletTransactionsSuccessResponseTransactionsTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    transaction_detail: V2WalletTransactionsSuccessResponseTransactionsTransactionDetailEnum;
    /**
     * 
     * @type {number}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    amount: number;
    /**
     * 
     * @type {V2WalletTransactionsSuccessResponseCampaign}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    campaign: V2WalletTransactionsSuccessResponseCampaign;
    /**
     * 
     * @type {V2WalletTransactionsSuccessResponseUser}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    user: V2WalletTransactionsSuccessResponseUser;
    /**
     * 
     * @type {V2WalletTransactionsSuccessResponseOrder}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    order?: V2WalletTransactionsSuccessResponseOrder;
    /**
     * 
     * @type {V2WalletTransactionsSuccessResponseTransferDetails}
     * @memberof V2WalletTransactionsSuccessResponseTransactions
     */
    transfer_details?: V2WalletTransactionsSuccessResponseTransferDetails;
}

/**
    * @export
    * @enum {string}
    */
export enum V2WalletTransactionsSuccessResponseTransactionsTransactionTypeEnum {
    Income = 'INCOME',
    Expense = 'EXPENSE'
}
/**
    * @export
    * @enum {string}
    */
export enum V2WalletTransactionsSuccessResponseTransactionsTransactionDetailEnum {
    InitialCost = 'INITIAL_COST',
    Income = 'INCOME',
    CancelCampaign = 'CANCEL_CAMPAIGN',
    RunCampaign = 'RUN_CAMPAIGN',
    PauseCampaign = 'PAUSE_CAMPAIGN',
    RerunCampaign = 'RERUN_CAMPAIGN',
    RejectCampaign = 'REJECT_CAMPAIGN',
    FinishCampaign = 'FINISH_CAMPAIGN',
    UpdateCampaign = 'UPDATE_CAMPAIGN',
    Transfer = 'TRANSFER',
    Subscription = 'SUBSCRIPTION'
}



