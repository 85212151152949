/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2MobileBranchCampaignCreateInput
 */
export interface V2MobileBranchCampaignCreateInput {
    /**
     * 
     * @type {string}
     * @memberof V2MobileBranchCampaignCreateInput
     */
    budget: string;
    /**
     * 
     * @type {string}
     * @memberof V2MobileBranchCampaignCreateInput
     */
    stop_time: string;
    /**
     * 
     * @type {string}
     * @memberof V2MobileBranchCampaignCreateInput
     */
    campaign_id: string;
    /**
     * 
     * @type {number}
     * @memberof V2MobileBranchCampaignCreateInput
     */
    location_radius: number;
    /**
     * 
     * @type {string}
     * @memberof V2MobileBranchCampaignCreateInput
     */
    targeting_type: V2MobileBranchCampaignCreateInputTargetingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum V2MobileBranchCampaignCreateInputTargetingTypeEnum {
    Pmax = 'PMAX',
    Smart = 'SMART',
    WhatsappMessage = 'WHATSAPP_MESSAGE',
    Messenger = 'MESSENGER',
    CallNow = 'CALL_NOW',
    FinalUrl = 'FINAL_URL',
    InstagramVisit = 'INSTAGRAM_VISIT',
    LeadGeneration = 'LEAD_GENERATION',
    AndroidAppInstalls = 'ANDROID_APP_INSTALLS',
    IosAppInstalls = 'IOS_APP_INSTALLS',
    OutcomeAwareness = 'OUTCOME_AWARENESS',
    OutcomeSales = 'OUTCOME_SALES',
    GetDirections = 'GET_DIRECTIONS'
}



