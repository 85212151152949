import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ImageAttachmentsBranchV2Api } from 'api/api';

import { useAuth, useAxios } from '@app/hooks';

export function deleteProfilePicture(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ImageAttachmentsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteProfilePicture(branchId).then((res) => res.data);
}

export default function useDeleteProfilePicture(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation(() => deleteProfilePicture(branchId, axiosInstance), props);
}
